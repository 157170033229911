import * as React from "react";
import {
  makeStyles,
  Button,
  Menu,
  MenuTrigger,
  MenuButton,
  MenuPopover,
  MenuList,
  MenuItem,
} from "@fluentui/react-components";
import { WrenchFilled, CalendarMonthRegular } from "@fluentui/react-icons";
import { useQueryContext } from "../context/QueryContext";
import { handleDuplicateFile, handleReportQuery, manageQueries, showDialog } from "../../utilities/excel";
import { useQueryRefresh } from "../../utilities/useQueryRefresh";
import { useDialog } from "../context/DialogContext";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    width: "auto",
    height: "auto",
    boxSizing: "border-box",
    padding: "15px",
    gap: "15px",
    "> *": {
      textOverflow: "ellipsis",
    },
    "> :not(:first-child)": {
      marginTop: "0px",
    },
    "> *:not(.ms-StackItem)": {
      flexShrink: 1,
    },
  },
  headerButtons: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "auto",
    justifyContent: "space-around",
    gap: "25px",
  },
  manageQueries: {
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  menuButton: {
    alignItems: "flex-start",
    width: "100%",
  },
});

const Header = (props) => {
  const styles = useStyles();
  const { loadQueriesFromSheet, queries, getSelectedQuery } = useQueryContext();
  const token = localStorage.getItem("DS-TOKEN");
  const userName = localStorage.getItem("DS-EXCEL-USERNAME");
  const { dialogOpen, setDialogOpen } = useDialog();
  const selectedQuery = getSelectedQuery();
  const { handleRefreshAll, handleRefreshActiveSheetQueries } = useQueryRefresh(
    setDialogOpen,
    loadQueriesFromSheet,
    token,
    userName
  );

  return (
    <div className={styles.root}>
      <div className={styles.headerButtons} id="header-buttons">
        {/* <Button className="hidden w-full" appearance="secondary" icon={<CalendarMonthRegular />}>
          Schedule
        </Button> */}
        <Menu className="w-full">
          <MenuTrigger disableButtonEnhancement>
            <MenuButton className={styles.menuButton} appearance="secondary" icon={<WrenchFilled />}>
              Tools
            </MenuButton>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              <MenuItem onClick={() => handleRefreshAll(queries)}>Refresh all queries</MenuItem>
              <MenuItem onClick={() => handleRefreshActiveSheetQueries(queries)}>Refresh active sheet</MenuItem>
              <MenuItem onClick={() => manageQueries()}>Manage queries</MenuItem>
              <MenuItem onClick={() => handleDuplicateFile()}>Duplicate this file</MenuItem>
              <MenuItem
                onClick={() => {
                  if (!selectedQuery) showDialog("Warning!", ["You do not have any parameters selected"]);
                  handleReportQuery(token, selectedQuery);
                }}
              >
                Report an issue with a query
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
