import * as React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  makeStyles,
  Button,
  Caption2,
} from "@fluentui/react-components";
import { useMutation } from "react-query";
import {
  TableFilled,
  LinkMultipleFilled,
  ArrowLeft16Filled,
  PersonCircleFilled,
  TextBulletListSquareFilled,
  QuestionCircleFilled,
  SettingsFilled,
} from "@fluentui/react-icons";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useQueryContext } from "../context/QueryContext";
import ConnectorsAccordion from "./ConnectorsAccordion";
import {
  generateTable,
  getAccountsByTaskId,
  getConnectors,
  getDatasourcesOptions,
  getMetrics,
} from "../../services/connector";
import AccountsList from "./AccountsList";
import DataTableAccordion from "./DataTableAccordion";
import OptionsAccordion from "./OptionsAccordion";
import { useDialog } from "../context/DialogContext";
import { getInitialEndDate, getInitialStartDate, onFormatDate } from "../../utilities/formatDates";
import { generateUUID, getUserTimeZone, showDialog, writeDataToExcel } from "../../utilities/excel";
import RunningDialog from "./RunningDialog";
import { config } from "../../Constants";
import ReportConfigAccordion from "./ReportConfigAccordion";
import DynamicForm from "./DynamicFormAccordion";
import { buildQueryRequest } from "../../utilities/parametersForSpecialConnectors";
import { loginOptionConnectors } from "../../utilities/connectorsConfiguration";

const useStyles = makeStyles({
  accordionHeader: {
    backgroundColor: "lightgray",
  },
  generateTable: {
    paddingRight: "15px",
    paddingLeft: "15px",
  },
  padding0: {
    padding: "0px!important",
  },
  marginPanel: {
    marginBottom: "80px!important",
  },
  accountsPanel: { height: "400px" },
  infoButton: { right: "40px", position: "absolute" },
});

const MainAccordion = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { dialogOpen, setDialogOpen } = useDialog();
  const { addQuery, getSelectedQuery, updateQuery, setSelectedQueryId, isDuplicating } = useQueryContext();
  const token = localStorage.getItem("DS-TOKEN");
  const userName = localStorage.getItem("DS-EXCEL-USERNAME");

  const [stablishedConnection, setStablishedConnection] = React.useState(false);
  const [taskId, setTaskId] = React.useState(null);
  const [hasWrittenData, setHasWrittenData] = React.useState(false);
  const [tableTaskId, setTableTaskId] = React.useState(null);
  const [accounts, setAccounts] = React.useState([]);
  const [connectedConnectorName, setConnectedConnectorName] = React.useState("");
  const [entryConnector, setEntryConnector] = React.useState(null);

  const [infoConnector, setInfoConnector] = React.useState(null);
  const [selectedDimsOptions, setSelectedDimsOptions] = React.useState([]);
  const [selectedDimsColsOptions, setSelectedDimsColsOptions] = React.useState([]);
  const [selectedMetricsOptions, setSelectedMetricsOptions] = React.useState([]);
  const [selectedAccounts, setSelectedAccounts] = React.useState([]);
  const [selectedConnectors, setSelectedConnectors] = React.useState([]);
  const [selectedReportOption, setSelectedReportOption] = React.useState(null);
  const [selectedSpecialSettings, setSelectedSpecialSettings] = React.useState({});
  const [configuration, setConfiguration] = React.useState([]);

  const [appliedFilters, setAppliedFilters] = React.useState([]);
  const [appliedMetricsSorts, setAppliedMetricsSorts] = React.useState([]);
  const [appliedDimsSorts, setAppliedDimsSorts] = React.useState([]);
  const [dates, setDates] = React.useState({
    start_date: getInitialStartDate(),
    end_date: getInitialEndDate(),
    date_range_type: "custom",
  });
  const [limits, setLimits] = React.useState({
    limitRows: 0,
    limitCols: 0,
  });
  const startTimeRef = React.useRef(null);
  const selectedQuery = getSelectedQuery();
  const connectorsRef = React.useRef(null);
  const [expandedItem, setExpandedItem] = React.useState(null);
  const [dynamicFormValues, setDynamicFormValues] = React.useState({});

  //Fetch connectors
  const { data, refetch } = useQuery(
    ["datasources", token],
    async () => {
      const response = await getConnectors({ token });
      if (response?.ok) return await response.json();
      throw new Error("Error fetching models");
    },
    { staleTime: Infinity }
  );

  // Fetch metrics
  const { data: allMetrics } = useQuery(
    ["metrics", entryConnector, selectedReportOption],
    async () => {
      const fetchConnector = loginOptionConnectors.find((connector) => connector.key === entryConnector);
      let response;
      if (fetchConnector) {
        response = await getDatasourcesOptions(userName, entryConnector, config.SERVICE_ID);
      } else {
        response = await getMetrics({ entryConnector, token, call_type: selectedReportOption });
      }
      if (response?.ok) {
        if (fetchConnector) {
          const options = await response.json();
          return { options: options[fetchConnector.name] };
        } else {
          return await response.json();
        }
      }
      throw new Error("Error fetching metrics");
    },
    {
      enabled: !!entryConnector,
      staleTime: Infinity,
    }
  );

  const dimensions = allMetrics?.result?.dimensions || [];
  const metrics = allMetrics?.result?.metrics || [];
  const options = allMetrics?.options || [];
  const filters = allMetrics?.filters || [];
  const reportOptions = allMetrics?.report_options || [];

  let fallbackConfig = null;

  if (!dimensions.length && !metrics.length && !options.length) {
    fallbackConfig = configuration?.accordions;
  }
  // Mutation for fetching accounts by TaskId
  const mutation = useMutation(
    async ({ taskId }) => {
      setDialogOpen(true);
      const response = await getAccountsByTaskId({ taskId });
      if (response?.ok) return await response.json();
      throw new Error("Error fetching accounts");
    },
    {
      onSuccess: (data) => {
        if (data.finished && data.ok) {
          setAccounts(data.result.accounts);
          setDialogOpen(false);
          setStablishedConnection(true);
        } else if (!data.ok && data.finished) {
          showDialog(data.title_error, data.error);
          setDialogOpen(false);
        } else {
          setTimeout(() => {
            mutation.mutate({ taskId });
          }, 1000);
        }
      },
    }
  );

  React.useEffect(() => {
    if (taskId) {
      mutation.mutate({ taskId });
    }
  }, [taskId]);

  // Handle Connection Change
  const handleConnectionChange = React.useCallback((connectorName, entryConnector) => {
    setConnectedConnectorName(connectorName);
    setEntryConnector(entryConnector);
  }, []);

  // Handle TaskId Change
  const handleTaskIdChange = React.useCallback((newTaskId) => {
    setTaskId(newTaskId);
  }, []);
  const requestBody = React.useMemo(
    () => ({
      dates: {
        date_range_type: dates.date_range_type,
        start_date: onFormatDate(dates.start_date),
        end_date: onFormatDate(dates.end_date),
      },
      timezone: getUserTimeZone(),
      sort_by: appliedMetricsSorts.map((sort) => [sort.metric, sort.order]),
      sort_dims_by: appliedDimsSorts.map((sort) => [sort.dim, sort.order]),
      options: selectedSpecialSettings && selectedSpecialSettings,
      high_priority: true,
      compare_dates: {},
      service_id: config.SERVICE_ID,
      accounts: selectedAccounts.map((account) => ({
        connection_id: account.connection_id || "",
        id: account.id || "",
        email: account.email || "",
        profile_id: account.profile_id || "",
        name: account.name?.toString() || "",
      })),
      metrics: selectedMetricsOptions,
      dimensions: {
        rows: selectedDimsOptions.map((dim) => dim.id),
        cols: selectedDimsColsOptions.map((dim) => dim.id),
        limit_rows: limits.limitRows,
        limit_cols: limits.limitCols,
      },
      filters: appliedFilters,
      sheet_name: dynamicFormValues && dynamicFormValues.sheet_name,
      sheet_range: dynamicFormValues && dynamicFormValues.sheet_range,
      document_url: dynamicFormValues && dynamicFormValues.URL,
      call_type: dynamicFormValues && dynamicFormValues.queryType,
      num_posts_by_hashtag: dynamicFormValues && dynamicFormValues.num_posts_by_hashtag,
      hashtags: dynamicFormValues && dynamicFormValues.hashtags,
      usernames: dynamicFormValues && dynamicFormValues.usernames,
      post_search_type: dynamicFormValues && dynamicFormValues.post_search_type,
      url: dynamicFormValues && dynamicFormValues.url,
      request: (dynamicFormValues && dynamicFormValues.request) || "",
      type: dynamicFormValues && dynamicFormValues.type,
      headers: (dynamicFormValues && dynamicFormValues.headers) || "",
      json_path: (dynamicFormValues && dynamicFormValues.json_path) || "",
      query: dynamicFormValues && dynamicFormValues.query,
      connections:
        selectedConnectors && entryConnector && selectedConnectors[entryConnector]
          ? selectedConnectors[entryConnector].map((connection) => ({
              id: connection.id,
            }))
          : [],
      page_ids: dynamicFormValues && dynamicFormValues.page_ids,
      number_of_posts: dynamicFormValues && dynamicFormValues.number_of_posts,
      gprop: dynamicFormValues && dynamicFormValues.gprop,
      category: dynamicFormValues && dynamicFormValues.category,
      search_term: dynamicFormValues && dynamicFormValues.search_term,
      region: dynamicFormValues && dynamicFormValues.region,
      country: dynamicFormValues && dynamicFormValues.region,
      year: dynamicFormValues && dynamicFormValues.year,
    }),
    [
      dates,
      appliedMetricsSorts,
      appliedDimsSorts,
      selectedAccounts,
      selectedDimsOptions,
      selectedDimsColsOptions,
      selectedMetricsOptions,
      limits,
      appliedFilters,
      dynamicFormValues,
      selectedSpecialSettings,
      selectedConnectors,
    ]
  );

  // Generate Table
  const handleGenerateTable = React.useCallback(() => {
    setHasWrittenData(false);
    generateTableMutation.mutate(requestBody);
    setDialogOpen(true);
  }, [generateTableMutation, requestBody]);

  // Generate Table Mutation
  const generateTableMutation = useMutation(
    async (requestData) => {
      const response = await generateTable({
        datasource: entryConnector,
        token: token,
        email: userName,
        creatorUserEmail: userName,
        previewData: false,
        requestData: requestData,
      });
      if (response?.ok) return await response.json();
      throw new Error("Error generating table");
    },
    {
      onSuccess: (result) => {
        if (!result.ok) {
          showDialog(result.title_error, result.error);
          setDialogOpen(false);
        }
        setTableTaskId(result.task_id);
      },
      onError: (error) => {
        showDialog("Warning message", "There is no data for the specified input");
        setDialogOpen(false);
      },
    }
  );

  // Combined Query Data
  const combinedQueryData = React.useMemo(
    () => ({
      ...selectedQuery,
      accountsViews: JSON.stringify(selectedAccounts),
      metrics: JSON.stringify(selectedMetricsOptions),
      dimensions: JSON.stringify(selectedDimsOptions),
      pivotDimensions: JSON.stringify(selectedDimsColsOptions),
      filters: JSON.stringify(appliedFilters),
      sort_by: JSON.stringify(appliedMetricsSorts.map((sort) => [sort.metric, sort.order])),
      sort_dims_by: JSON.stringify(appliedDimsSorts.map((sort) => [sort.dim, sort.order])),
      maxRows: limits.limitRows,
      maxPivotCategories: limits.limitCols,
      options: JSON.stringify(selectedSpecialSettings),
      specialSettings: JSON.stringify(selectedSpecialSettings),
      otherParameters: JSON.stringify(dynamicFormValues),
    }),
    [
      dates,
      appliedMetricsSorts,
      appliedDimsSorts,
      selectedAccounts,
      selectedDimsOptions,
      selectedDimsColsOptions,
      selectedMetricsOptions,
      limits,
      appliedFilters,
      dynamicFormValues,
    ]
  );

  const handleAccountSelection = (connectorsRef, selectedQuery) => {
    if (connectorsRef && selectedQuery) {
      JSON.parse(selectedQuery.refreshWithUserAccount).forEach((account) => {
        connectorsRef.handleCheckboxChange(selectedQuery.dataSource, account);
      });
    }
  };

  const confirmConnection = (connectorsRef, selectedQuery, data) => {
    if (connectorsRef && selectedQuery && data) {
      connectorsRef.confirmConnection(
        selectedQuery.dataSource,
        data.datasources[selectedQuery.dataSource].name,
        data.datasources[selectedQuery.dataSource]
      );
    }
  };

  const applySelections = (
    selectedQuery,
    setSelectedAccounts,
    setSelectedMetricsOptions,
    setSelectedDimsOptions,
    setSelectedDimsColsOptions,
    setAppliedFilters,
    setAppliedMetricsSorts,
    setAppliedDimsSorts,
    setLimits,
    setSelectedSpecialSettings
  ) => {
    if (selectedQuery) {
      setSelectedAccounts(JSON.parse(selectedQuery.accountsViews));
      setSelectedMetricsOptions(JSON.parse(selectedQuery.metrics));
      setSelectedDimsOptions(selectedQuery.dimensions ? JSON.parse(selectedQuery.dimensions) : []);
      setSelectedDimsColsOptions(selectedQuery.pivotDimensions ? JSON.parse(selectedQuery.pivotDimensions) : []);
      setAppliedFilters(JSON.parse(selectedQuery.filters));
      setSelectedSpecialSettings(JSON.parse(selectedQuery.specialSettings));
      setAppliedMetricsSorts(
        selectedQuery.sort ? JSON.parse(selectedQuery.sort).map((sort) => ({ metric: sort[0], order: sort[1] })) : []
      );

      setAppliedDimsSorts(
        selectedQuery.sortDimensions
          ? JSON.parse(selectedQuery.sortDimensions).map((sort) => ({ dim: sort[0], order: sort[1] }))
          : []
      );

      setLimits({
        limitRows: selectedQuery.maxRows || 0,
        limitCols: selectedQuery.maxPivotCategories || 0,
      });
    }
  };

  const waitForRefAndInitialize = React.useCallback(() => {
    const tryInitialize = () => {
      if (connectorsRef.current) {
        handleAccountSelection(connectorsRef.current, selectedQuery);
      } else {
        window.requestAnimationFrame(tryInitialize);
      }
    };
    tryInitialize();
  }, [selectedQuery, connectorsRef]);

  React.useEffect(() => {
    if (selectedQuery && Object.keys(selectedConnectors).length > 0) {
      confirmConnection(connectorsRef.current, selectedQuery, data);
      applySelections(
        selectedQuery,
        setSelectedAccounts,
        setSelectedMetricsOptions,
        setSelectedDimsOptions,
        setSelectedDimsColsOptions,
        setAppliedFilters,
        setAppliedMetricsSorts,
        setAppliedDimsSorts,
        setLimits,
        setSelectedSpecialSettings
      );
    }
  }, [
    selectedConnectors,
    selectedQuery,
    data,
    setSelectedAccounts,
    setSelectedSpecialSettings,
    setSelectedMetricsOptions,
    setSelectedDimsOptions,
    setSelectedDimsColsOptions,
    setAppliedFilters,
    setAppliedMetricsSorts,
    setAppliedDimsSorts,
    setLimits,
  ]);

  const initializeEditing = React.useCallback(() => {
    if (selectedQuery) {
      setExpandedItem("connectors");
      waitForRefAndInitialize();
    }
  }, [selectedQuery, waitForRefAndInitialize]);

  React.useEffect(() => {
    initializeEditing();
  }, [initializeEditing]);

  const { data: tableData, refetch: tableDataRefetch } = useQuery(
    ["accountsByTaskId", tableTaskId],
    async () => {
      const response = await getAccountsByTaskId({ taskId: tableTaskId });
      if (response?.ok) {
        const result = await response.json();
        return result;
      } else {
        throw new Error("Error fetching accounts");
      }
    },
    {
      enabled: !!tableTaskId,
      onSuccess: (data) => {
        if (data?.ok && data?.finished && !hasWrittenData) {
          const endTime = performance.now();
          const executionTime = ((endTime - startTimeRef.current) / 1000).toFixed(2);
          const queryUUID = generateUUID();
          const requestBodyExcel = buildQueryRequest(
            entryConnector,
            requestBody,
            dynamicFormValues,
            selectedConnectors,
            selectedDimsOptions,
            selectedDimsColsOptions
          );
          writeDataToExcel(
            data.result,
            "A1",
            isDuplicating || !selectedQuery ? queryUUID : combinedQueryData.queryUUID,
            isDuplicating || !selectedQuery ? requestBodyExcel : combinedQueryData,
            isDuplicating || !selectedQuery ? entryConnector : combinedQueryData.dataSource,
            executionTime,
            isDuplicating || !selectedQuery ? addQuery : updateQuery,
            isDuplicating || !selectedQuery ? false : true
          ).then(() => {
            setHasWrittenData(true);
            setSelectedQueryId(queryUUID);
          });
          setDialogOpen(false);
        } else if (!data?.ok && data?.finished) {
          showDialog(data.title_error, data.error);
          setTableTaskId(null);
          setDialogOpen(false);
        } else if (!data?.finished) {
          setTimeout(() => {
            tableDataRefetch();
          }, 3000);
        }
      },
      onError: (error) => {
        showDialog("Warning message", "ERROR");
        setDialogOpen(false);
      },
    }
  );

  React.useEffect(() => {
    if (tableTaskId && !hasWrittenData) {
      startTimeRef.current = performance.now();
      tableDataRefetch();
    }
  }, [tableTaskId, hasWrittenData]);

  return (
    <>
      <RunningDialog dialogOpen={dialogOpen} />
      <div className={styles.root}>
        <div className={styles.generateTable}>
          <div id="back-to-queries">
            <Button
              className={styles.padding0}
              appearance="transparent"
              onClick={() => {
                setSelectedQueryId(null);
                navigate("/");
              }}
              icon={<ArrowLeft16Filled />}
            >
              Manage Queries
            </Button>
          </div>
          {(!selectedQuery || isDuplicating) && (
            <div id="generate-table-button" className="my-4">
              <Button
                disabled={!stablishedConnection}
                onClick={handleGenerateTable}
                className="w-full"
                appearance="primary"
              >
                Generate table
              </Button>
            </div>
          )}
          {selectedQuery && !isDuplicating && (
            <div id="generate-table-button" className="my-4">
              <div className="flex gap-5">
                <Button onClick={handleGenerateTable} className="w-full" appearance="primary">
                  Confirm changes
                </Button>
                <Button className="w-full" appearance="secondary">
                  Cancel
                </Button>
              </div>
              <span>
                Modifying query at {selectedQuery?.sheetName || ""} in range {selectedQuery?.rangeAddress || ""}
              </span>
            </div>
          )}
        </div>
        <div id="add-query">
          <Accordion
            collapsible
            openItems={expandedItem}
            onToggle={(e, data) => {
              setExpandedItem(data.openItems);
            }}
          >
            <AccordionItem key="connectors" value="connectors">
              <AccordionHeader
                expandIconPosition="end"
                className={styles.accordionHeader}
                icon={<LinkMultipleFilled />}
              >
                <span>
                  CONNECTORS{" "}
                  <Caption2>
                    {stablishedConnection && connectedConnectorName && ` - ${connectedConnectorName}`}
                  </Caption2>
                </span>
                {/* CONNECTORS {stablishedConnection && connectedConnectorName && ` - ${connectedConnectorName}`} */}
              </AccordionHeader>
              <AccordionPanel className={styles.marginPanel}>
                <ConnectorsAccordion
                  datasources={data || []}
                  onConnectionChange={handleConnectionChange}
                  onTaskIdChange={handleTaskIdChange}
                  selectedQuery={selectedQuery}
                  ref={connectorsRef}
                  selectedConnectors={selectedConnectors}
                  onChangeConnectors={setSelectedConnectors}
                  infoConnector={infoConnector}
                  setInfoConnector={setInfoConnector}
                  setConfiguration={setConfiguration}
                  setStablishedConnection={setStablishedConnection}
                  onAccountsChange={setSelectedAccounts}
                />
              </AccordionPanel>
            </AccordionItem>
            {configuration && configuration.accordions?.reportConfiguration && (
              <AccordionItem value="reportConfiguration">
                <AccordionHeader expandIconPosition="end" className={styles.accordionHeader} icon={<SettingsFilled />}>
                  REPORT CONFIGURATION
                </AccordionHeader>
                <AccordionPanel className={styles.marginPanel}>
                  <ReportConfigAccordion
                    setSelectedReportOption={setSelectedReportOption}
                    reportOptions={reportOptions}
                    sections={configuration.accordions.reportConfiguration.sections}
                    setDynamicFormValues={setDynamicFormValues}
                  />
                </AccordionPanel>
              </AccordionItem>
            )}
            {stablishedConnection && infoConnector?.has_all_accounts === true && (
              <>
                <AccordionItem value="2">
                  <AccordionHeader
                    expandIconPosition="end"
                    className={styles.accordionHeader}
                    icon={<PersonCircleFilled />}
                  >
                    YOUR ACCOUNTS
                    <span className="accountText">
                      {selectedAccounts && selectedAccounts.map((account) => account.name).join(", ")}
                    </span>
                  </AccordionHeader>
                  <AccordionPanel className={styles.accountsPanel}>
                    <AccountsList
                      accounts={accounts}
                      selectedAccounts={selectedAccounts}
                      onAccountsChange={setSelectedAccounts}
                    />
                  </AccordionPanel>
                </AccordionItem>
              </>
            )}
            <AccordionItem value="3">
              <AccordionHeader expandIconPosition="end" className={styles.accordionHeader} icon={<TableFilled />}>
                DATA TABLE
              </AccordionHeader>
              <AccordionPanel>
                {dimensions.length || metrics.length || filters.length ? (
                  <DataTableAccordion
                    datasource={entryConnector}
                    filters={filters}
                    dimensions={dimensions}
                    sourceMetrics={metrics}
                    selectedDimsOptions={selectedDimsOptions || []}
                    selectedDimsColsOptions={selectedDimsColsOptions || []}
                    selectedMetricsOptions={selectedMetricsOptions || []}
                    appliedFilters={appliedFilters || []}
                    appliedMetricsSorts={appliedMetricsSorts || []}
                    appliedDimsSorts={appliedDimsSorts || []}
                    setSelectedDimsOptions={setSelectedDimsOptions}
                    setSelectedDimsColsOptions={setSelectedDimsColsOptions}
                    setSelectedMetricsOptions={setSelectedMetricsOptions}
                    setAppliedFilters={setAppliedFilters}
                    setAppliedMetricsSorts={setAppliedMetricsSorts}
                    setAppliedDimsSorts={setAppliedDimsSorts}
                    onDatesChange={setDates}
                    dates={dates}
                    setDates={setDates}
                    limits={limits}
                    setLimits={setLimits}
                  />
                ) : fallbackConfig ? (
                  <DynamicForm sections={fallbackConfig?.dataTable?.sections} onValuesChange={setDynamicFormValues} />
                ) : (
                  <div>No data available for this connector.</div>
                )}
              </AccordionPanel>
            </AccordionItem>
            {stablishedConnection && (allMetrics?.options?.length > 0 || configuration?.accordions?.options) && (
              <AccordionItem value="4">
                <AccordionHeader
                  expandIconPosition="end"
                  className={styles.accordionHeader}
                  icon={<TextBulletListSquareFilled />}
                >
                  <span>
                    OPTIONS{" "}
                    <Caption2>
                      {selectedSpecialSettings &&
                        Object.keys(selectedSpecialSettings).length > 0 &&
                        `${Object.keys(selectedSpecialSettings).length} selected`}
                    </Caption2>
                  </span>
                  <Button
                    as="a"
                    href="https://support.dataslayer.ai/dataslayerai-advanced-options"
                    className={styles.infoButton}
                    appearance="transparent"
                    icon={<QuestionCircleFilled />}
                  />
                </AccordionHeader>
                <AccordionPanel>
                  {
                    allMetrics?.options?.length > 0 && (
                      <OptionsAccordion
                        options={allMetrics.options}
                        onOptionsChange={setSelectedSpecialSettings}
                        selectedOptions={selectedSpecialSettings}
                      />
                    )

                    // : (
                    //   configuration?.accordions?.options?.sections.map((section, index) => (
                    //     <div key={index}>
                    //       <h3>{section.title}</h3>
                    //       {section.fields.map((field, fieldIndex) => (
                    //         <Field key={fieldIndex} field={field} />
                    //       ))}
                    //     </div>
                    //   ))
                  }
                </AccordionPanel>
              </AccordionItem>
            )}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default MainAccordion;
