import React, { useState } from "react";
import {
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerGroup,
  TagPickerOption,
  TagPickerOptionGroup,
  makeStyles,
  Button,
  Label,
} from "@fluentui/react-components";
import { Tag, Field } from "@fluentui/react-components";
import { DeleteFilled } from "@fluentui/react-icons";

const useStyles = makeStyles({
  tagPickerWrapper: {
    position: "relative",
    height: "300px",
  },
  tagPickerList: {
    position: "absolute",
    top: "60px",
    width: "100%",
    zIndex: 1,
    backgroundColor: "white",
    border: "1px solid #ccc",
    borderRadius: "4px",
    maxHeight: "240px",
    overflowY: "auto",
  },
  accountGroupName: {
    display: "flex",
    textAlign: "center",
    fontWeight: "bold!important",
    fontStyle: "italic",
    color: "#b521cf",
  },
  label: {
    color: "#b521cf",
    fontWeight: "bold",
  },
});

const AccountsList = ({ accounts, onAccountsChange, selectedAccounts }) => {
  const styles = useStyles();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filter, setFilter] = useState("");
  const onOptionSelect = (e, data) => {
    const selectedOptionId = data.selectedOptions[data.selectedOptions.length - 1];
    const isAlreadySelected = selectedOptions.some((option) => option.id === selectedOptionId);

    let updatedSelectedOptions;

    if (isAlreadySelected) {
      updatedSelectedOptions = selectedOptions.filter((option) => option.id !== selectedOptionId);
    } else {
      let foundAccount = null;
      let connectionId = null;
      for (const [email, groups] of Object.entries(accounts)) {
        for (const group of Object.values(groups)) {
          const account = group.find((acc) => acc.id === selectedOptionId);
          if (account) {
            foundAccount = account;
            connectionId = email;
            break;
          }
        }
        if (foundAccount) break;
      }

      if (foundAccount) {
        updatedSelectedOptions = [
          ...selectedOptions,
          {
            ...foundAccount,
            connection_id: connectionId,
            email: connectionId,
          },
        ];
      } else {
        updatedSelectedOptions = [...selectedOptions];
      }
    }
    setSelectedOptions(updatedSelectedOptions);
    onAccountsChange(updatedSelectedOptions);
  };

  const onInputChange = (e) => {
    setFilter(e.target.value.toLowerCase());
  };

  const filteredAccounts = () => {
    if (!filter) return accounts;
    const filtered = {};
    Object.keys(accounts).forEach((email) => {
      filtered[email] = {};
      Object.keys(accounts[email]).forEach((group) => {
        filtered[email][group] = accounts[email][group].filter((account) =>
          account.name.toString().toLowerCase().includes(filter)
        );
      });
    });
    return filtered;
  };

  const filteredData = filteredAccounts();

  const handleSelectAll = (groupKey) => {
    const allOptions = [];
    Object.keys(accounts[groupKey]).forEach((accountGroup) => {
      accounts[groupKey][accountGroup].forEach((account) => {
        allOptions.push({ ...account, connection_id: groupKey, email: groupKey });
      });
    });

    const getUniqueOptions = (prevOptions, newOptions) => {
      const combinedOptions = [...prevOptions, ...newOptions];
      return combinedOptions.reduce((acc, current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          acc.push(current);
        }
        return acc;
      }, []);
    };

    setSelectedOptions((prevSelectedOptions) => getUniqueOptions(prevSelectedOptions, allOptions));

    onAccountsChange((prevSelectedOptions) => getUniqueOptions(prevSelectedOptions, allOptions));
  };

  return (
    <Field style={{ maxWidth: 400 }}>
      <div className="flex justify-between items-center">
        <span className={styles.label}>You can select unlimited accounts</span>
        <Button
          onClick={() => {
            onAccountsChange([]);
            setSelectedOptions([]);
          }}
          appearance="transparent"
          icon={<DeleteFilled />}
        />
      </div>
      <div className={styles.tagPickerWrapper}>
        <TagPicker
          inline={true}
          positioning={{ position: "below", align: "bottom", pinned: true, disableUpdateOnResize: true }}
          open={true}
          onOptionSelect={onOptionSelect}
          selectedOptions={selectedAccounts}
        >
          <TagPickerControl>
            <TagPickerGroup>
              {selectedAccounts.map((option) => (
                <Tag key={option.id} shape="rounded" value={option.id}>
                  {option.name}
                </Tag>
              ))}
            </TagPickerGroup>
            <TagPickerInput aria-label="Select Employees" onChange={onInputChange} />
          </TagPickerControl>
          <TagPickerList>
            {Object.keys(accounts).map((groupKey) => (
              <div key={groupKey} className={styles.accountGroup}>
                <Button appearance="transparent" onClick={() => handleSelectAll(groupKey)}>
                  Select all {groupKey}
                </Button>
              </div>
            ))}
            {Object.keys(filteredData).map((groupKey) => (
              <div key={groupKey} className={styles.accountGroup}>
                <Label id="account-label" className={styles.accountGroupName}>
                  {groupKey}
                </Label>
                {Object.keys(filteredData[groupKey]).map((accountGroup) => (
                  <TagPickerOptionGroup label={accountGroup} key={accountGroup}>
                    {filteredData[groupKey][accountGroup].map((account, index) => (
                      <TagPickerOption value={account.id} key={account.id} text={account.name}>
                        {account.name}
                      </TagPickerOption>
                    ))}
                  </TagPickerOptionGroup>
                ))}
              </div>
            ))}
          </TagPickerList>
        </TagPicker>
      </div>
    </Field>
  );
};

export default AccountsList;
