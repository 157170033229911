import { config } from "../Constants";

export async function fetchAPI({ endpoint, token, ...props }) {
  return fetch(`${config.API_URL}/${endpoint}`, {
    ...props,
    headers: {
      Authorization: `Bearer ${token}`,
      ...(props?.headers ?? {}),
    },
  });
}
