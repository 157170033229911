import * as React from "react";
import { makeStyles, Caption1, Caption1Stronger } from "@fluentui/react-components";
import { cleanDate } from "../../utilities/formatDates";

const useStyles = makeStyles({});

const QueryInfoExpand = ({ queryInfo }) => {
  const styles = useStyles();
  return (
    <div className={styles.expandedContent}>
      <Caption1Stronger>Query information</Caption1Stronger>
      <div>
        <Caption1Stronger>Query UUID:</Caption1Stronger>
        <Caption1>{queryInfo.queryUUID}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Sheet name:</Caption1Stronger>
        <Caption1>{queryInfo.sheetName}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Range address:</Caption1Stronger>
        <Caption1>{queryInfo.rangeAddress}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Created:</Caption1Stronger>
        <Caption1>{queryInfo.created}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Updated:</Caption1Stronger>
        <Caption1>{queryInfo.updated}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Last Status:</Caption1Stronger>
        <Caption1>{queryInfo.lastStatus}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Connector:</Caption1Stronger>
        <Caption1>{queryInfo.dataSource}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Date range type:</Caption1Stronger>
        <Caption1>{queryInfo.dateRangeType}</Caption1>
      </div>
      {queryInfo && queryInfo.startDate && (
        <div>
          <Caption1Stronger>Start date:</Caption1Stronger>
          <Caption1>
            {/* {query ? formatDateTime(new Date(query.startDate), "Europe/Madrid", "yyyy-MM-dd") : ""} */}
            {queryInfo ? cleanDate(queryInfo.startDate) : ""}
          </Caption1>
        </div>
      )}
      {queryInfo && queryInfo.endDate && (
        <div>
          <Caption1Stronger>End date:</Caption1Stronger>
          <Caption1>
            {/* {query ? formatDateTime(new Date(query.endDate), "Europe/Madrid", "yyyy-MM-dd") : ""} */}
            {queryInfo ? cleanDate(queryInfo.endDate) : ""}
          </Caption1>
        </div>
      )}
      <div>
        <Caption1Stronger>Accounts:</Caption1Stronger>
        <Caption1>
          {" "}
          {JSON.parse(queryInfo.accountsViews)
            .map((account) => {
              if (account.name) {
                return account.name;
              } else if (account.usernames) {
                return account.usernames;
              } else if (account.hashtags) {
                return account.hashtags;
              }
              return "";
            })
            .join(", ")}
        </Caption1>
      </div>
      <div>
        <Caption1Stronger>Metrics:</Caption1Stronger>
        <Caption1>{queryInfo.metrics}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Dimensions:</Caption1Stronger>
        <Caption1>{queryInfo.dimensions}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Pivot dimensions:</Caption1Stronger>
        <Caption1>{queryInfo.pivotDimensions}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Filters:</Caption1Stronger>
        <Caption1>{queryInfo.filters}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Sort:</Caption1Stronger>
        <Caption1>{queryInfo.sort}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Sort dimensions:</Caption1Stronger>
        <Caption1>{queryInfo.sortDimensions}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Max rows:</Caption1Stronger>
        <Caption1>{queryInfo.maxRows || 0}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Max pivot categories:</Caption1Stronger>
        <Caption1>{queryInfo.maxPivotCategories || 0}</Caption1>
      </div>
      {queryInfo.specialSettings && (
        <div>
          <Caption1Stronger>Special settings:</Caption1Stronger>
          <Caption1>{queryInfo.specialSettings}</Caption1>
        </div>
      )}
      {queryInfo.otherParameters && (
        <div>
          <Caption1Stronger>Other parameters:</Caption1Stronger>
          <Caption1>{queryInfo.otherParameters}</Caption1>
        </div>
      )}
      {queryInfo.reportType && (
        <div>
          <Caption1Stronger>Report Type:</Caption1Stronger>
          <Caption1>{queryInfo.reportType}</Caption1>
        </div>
      )}
      <div>
        <Caption1Stronger>Refresh with user account:</Caption1Stronger>
        <Caption1>{queryInfo.refreshWithUserAccount}</Caption1>
      </div>
      <div>
        <Caption1Stronger>Created by user account:</Caption1Stronger>
        <Caption1>{queryInfo.createdByUserAccount}</Caption1>
      </div>
    </div>
  );
};

export default QueryInfoExpand;
