import { Field, Input, Select, Textarea } from "@fluentui/react-components";
import React, { useState, useCallback } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";

const SelectField = ({ id, label, value, options, onChange }) => (
  <Field className="flex flex-col mx-2 my-3" label={label}>
    <Select id={id} value={value} onChange={onChange}>
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </Select>
  </Field>
);

const TextareaField = ({ id, label, value, onChange }) => (
  <Field className="flex flex-col mx-2 my-3" label={label}>
    <Textarea id={id} value={value} onChange={onChange} />
  </Field>
);

const InputField = ({ id, label, value, onChange }) => (
  <Field className="flex flex-col mx-2 my-3" label={label}>
    <Input type="text" id={id} value={value} onChange={onChange} />
  </Field>
);

const CodeMirrorField = ({ id, label, value = "", mode = "sql", onChange }) => (
  <Field className="flex flex-col mx-2 my-3" label={label}>
    <CodeMirror
      id={id}
      value={value || ""} // Asegura que el valor siempre tenga algo (por defecto "")
      options={{
        mode: mode,
        theme: "eclipse",
        lineNumbers: true,
      }}
      onBeforeChange={(editor, data, value) => {
        onChange({ target: { value } });
      }}
    />
  </Field>
);

const DatabaseTables = ({ tables, onTableClick }) => (
  <table className="table table-container">
    <thead>
      <tr>
        <th>TABLES</th>
      </tr>
    </thead>
    <tbody className="block">
      {Object.keys(tables).map((tableName, index) => (
        <tr key={index} onClick={() => onTableClick(tableName)}>
          <td>{tableName}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

// Componente para renderizar las columnas de la tabla seleccionada
const DatabaseColumns = ({ tableName, columns }) => (
  <>
    {/* <h3>Columns in {tableName}</h3> */}
    <table className="table table-container">
      <thead>
        <tr>
          <th>COLUMNS</th>
        </tr>
      </thead>
      <tbody className="block">
        {columns.map((column, index) => (
          <tr key={index}>
            <td>{column}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
);

const FormSection = ({ section, values, handleChange, selectedQueryType }) => {
  if (selectedQueryType && section.visibleOn && section.visibleOn !== selectedQueryType) {
    return null;
  }

  return (
    <div className="form-section">
      {(() => {
        const commonProps = {
          id: section.id,
          label: section.name,
          value: values[section.id] || "",
          onChange: (e) => handleChange(section.id, e.target.value),
        };

        switch (section.type) {
          case "select":
            return <SelectField {...commonProps} options={section.values} />;
          case "textarea":
            return <TextareaField {...commonProps} />;
          case "input":
            return <InputField {...commonProps} />;
          case "codemirror":
            return <CodeMirrorField {...commonProps} mode={section.mode} />;
          default:
            return null;
        }
      })()}
    </div>
  );
};

const DynamicForm = ({ sections, onSubmit, onValuesChange, options }) => {
  const [values, setValues] = useState({ queryType: "" });
  const initialQueryType = sections.find((section) => section.name === "queryType")?.values[0].id;
  const initialDocType = sections.find((section) => section.name === "type")?.values[0].id;
  const [selectedTable, setSelectedTable] = useState(null);
  const [columns, setColumns] = useState([]);
  // Simulando una respuesta del backend
  const simulatedResponse = {
    accounts: {
      users: ["id", "name", "email"],
      orders: ["order_id", "product_name", "quantity"],
      products: ["product_id", "product_name", "price"],
    },
  };
  React.useEffect(() => {
    if (!values.queryType) {
      setValues((prevValues) => ({
        ...prevValues,
        queryType: initialQueryType,
        type: initialDocType,
      }));
    } else {
      onValuesChange && onValuesChange(values);
    }
  }, [initialQueryType, values.queryType, onValuesChange]);

  const handleChange = useCallback(
    (name, value) => {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      onValuesChange && onValuesChange({ ...values, [name]: value });
    },
    [values, onValuesChange]
  );

  const handleTableClick = (tableName) => {
    setSelectedTable(tableName);
    setColumns(simulatedResponse.accounts[tableName]);
  };
  return (
    <>
      {sections &&
        sections.map((section, sectionIndex) => (
          <FormSection
            key={sectionIndex}
            section={section}
            values={values}
            handleChange={handleChange}
            selectedQueryType={values.queryType}
          />
        ))}
      {options &&
        options.map((option, optionIndex) => (
          <FormSection
            key={optionIndex}
            section={option}
            values={values}
            handleChange={handleChange}
            selectedQueryType={values.queryType}
          />
        ))}

      <div className="flex flex-row justify-between p-2">
        <DatabaseTables tables={simulatedResponse.accounts} onTableClick={handleTableClick} />
        {selectedTable && <DatabaseColumns tableName={selectedTable} columns={columns} />}
      </div>
    </>
  );
};

export default DynamicForm;
