import * as React from "react";
import { Checkbox, Option, makeStyles, Label, Dropdown } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
  },
  checkbox: {
    marginBottom: "10px",
  },
  select: {
    marginTop: "10px",
  },
  selectLabel: {
    display: "block",
    marginBottom: "5px",
  },
});

const OptionsAccordion = ({ options, onOptionsChange, selectedOptions }) => {
  const styles = useStyles();

  const handleOptionChange = (id, checked) => {
    onOptionsChange((prevConfig) => ({
      ...prevConfig,
      [id]: checked,
    }));
  };

  return (
    <div className={styles.container}>
      {options &&
        options.map((option) => (
          <div key={option.id} className={styles.container}>
            {option.type === "boolean" ? (
              <Checkbox
                className={styles.checkbox}
                checked={!!selectedOptions[option.id]}
                onChange={(e, data) => handleOptionChange(option.id, data.checked)}
                label={option.name}
              />
            ) : option.type === "list" ? (
              <>
                <Label className={styles.selectLabel}>{option.name}</Label>
                <Dropdown
                  selectedOptions={selectedOptions[option.id]}
                  value={selectedOptions[option.id]}
                  onOptionSelect={(e, data) => handleOptionChange(option.id, data.optionValue)}
                  placeholder="Select an option"
                >
                  {option.values.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Dropdown>
              </>
            ) : null}
          </div>
        ))}
    </div>
  );
};

export default OptionsAccordion;
