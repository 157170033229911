import { createLightTheme } from "@fluentui/react-components";

export const exceladdintheme = {
  10: "#050205",
  20: "#221124",
  30: "#3A1740",
  40: "#4E1B57",
  50: "#631E6F",
  60: "#782088",
  70: "#8F21A2",
  80: "#A621BD",
  90: "#B82ED1",
  100: "#C24BD6",
  110: "#CB63DC",
  120: "#D37AE1",
  130: "#DB8FE6",
  140: "#E3A3EB",
  150: "#EAB7EF",
  160: "#F0CBF4",
};

const lightTheme = {
  ...createLightTheme(exceladdintheme),
  fontFamilyBase: "Barlow",
};

export { lightTheme };
