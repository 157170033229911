import { config } from "../Constants";
let loginDialog;
const dialogLoginUrl = `${config.EXCEL_DATASLAYER_URL}/login/login.html`;

export const signInO365 = (setAuthStatus, setToken, setUserName, displayError, setDSToken) => {
  setAuthStatus("loginInProcess");

  Office.context.ui.displayDialogAsync(dialogLoginUrl, { height: 40, width: 30 }, (result) => {
    if (result.status === Office.AsyncResultStatus.Failed) {
      displayError(`${result.error.code} ${result.error.message}`);
    } else {
      loginDialog = result.value;
      loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLoginMessage);
      loginDialog.addEventHandler(Office.EventType.DialogEventReceived, processLoginDialogEvent);
    }
  });

  const processLoginMessage = async (arg) => {
    if (arg.origin !== window.location.origin) {
      throw new Error("Incorrect origin passed to processLoginMessage.");
    }

    let messageFromDialog = JSON.parse(arg.message);
    if (messageFromDialog.status === "success") {
      loginDialog.close();
      setToken(messageFromDialog.token);
      setUserName(messageFromDialog.userName);
      localStorage.setItem("DS-EXCEL-TOKEN", messageFromDialog.token);
      localStorage.setItem("DS-EXCEL-USERNAME", messageFromDialog.userName);
      dialog.close();
    } else {
      loginDialog.close();
      displayError(messageFromDialog.result);
    }
  };

  const processLoginDialogEvent = (arg) => {
    processDialogEvent(arg, setAuthStatus, displayError);
  };
};

const processDialogEvent = (arg, setAuthStatus, displayError) => {
  switch (arg.error) {
    case 12002:
      displayError(
        "The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid."
      );
      break;
    case 12003:
      displayError("The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.");
      break;
    case 12006:
      // 12006 means that the user closed the dialog instead of waiting for it to close.
      // It is not known if the user completed the login or logout, so assume the user is
      // logged out and revert to the app's starting state. It does no harm for a user to
      // press the login button again even if the user is logged in.
      setAuthStatus("notLoggedIn");
      break;
    default:
      displayError("Unknown error in dialog box.");
      break;
  }
};

function delay(milliSeconds) {
  return new Promise((resolve) => setTimeout(resolve, milliSeconds));
}

let logoutDialog;
const dialogLogoutUrl = `${config.EXCEL_DATASLAYER_URL}/logout/logout.html`;

export const logoutFromO365 = async (setAuthStatus, setUserName, userName, displayError) => {
  Office.context.ui.displayDialogAsync(dialogLogoutUrl, { height: 40, width: 30 }, async (result) => {
    if (result.status === Office.AsyncResultStatus.Failed) {
      displayError(`${result.error.code} ${result.error.message}`);
    } else {
      logoutDialog = result.value;
      logoutDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLogoutMessage);
      logoutDialog.addEventHandler(Office.EventType.DialogEventReceived, processLogoutDialogEvent);
      await delay(5000); // Wait for dialog to initialize and register handler for messaging.
      logoutDialog.messageChild(JSON.stringify({ userName: userName }));
    }
  });

  const processLogoutMessage = () => {
    logoutDialog.close();
    setAuthStatus("notLoggedIn");
    setUserName("");
  };

  const processLogoutDialogEvent = (arg) => {
    processDialogEvent(arg, setAuthStatus, displayError);
  };
};
