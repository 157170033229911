import React, { useState, useEffect } from "react";
import DynamicForm from "./DynamicFormAccordion";

const reportConfigurations = {
  post_search: {
    queryType: "post_search",
    hashtags: "",
    post_search_type: "recent_media",
    num_posts_by_hashtag: "50",
  },
  profile_info: {
    queryType: "profile_info",
    usernames: "",
  },
  profile_posts: {
    queryType: "profile_posts",
    usernames: "",
  },
  interest_over_time: {
    queryType: "interest_over_time",
    search_term: "",
    region: "worldwide",
    gprop: "web",
    category: "0",
  },
  interest_by_region: {
    queryType: "profile_interest_by_regionposts",
    search_term: "",
    region: "worldwide",
    gprop: "web",
    category: 0,
  },
  related_queries: {
    queryType: "related_queries",
    search_term: "",
    region: "worldwide",
    gprop: "web",
    category: 0,
    type: "top",
  },
  related_topics: {
    queryType: "related_topics",
    search_term: "",
    region: "worldwide",
    gprop: "web",
    category: 0,
    type: "top",
  },
  related_suggestions: {
    queryType: "related_suggestions",
    search_term: "",
  },
  daily_search_trends: {
    queryType: "daily_search_trends",
    region: "worldwide",
  },
  top_charts: {
    queryType: "top_charts",
    region: "worldwide",
    year: "2004",
  },
  page: {
    queryType: "page",
  },
  post: {
    queryType: "page_posts",
  },
};

const ReportConfigAccordion = ({
  sections,
  reportOptionSelected,
  setSelectedReportOption,
  reportOptions,
  setDynamicFormValues,
}) => {

  const [selectedQueryType, setSelectedQueryType] = useState(sections[0].values[0].id);
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setSelectedQueryType(sections[0].values[0].id);
    setFormValues(reportConfigurations[sections[0].values[0].id] || {});
  }, [sections]);

  const handleQueryTypeChange = React.useCallback(
    (newValues) => {
      const selectedType = newValues.queryType || selectedQueryType;

      setSelectedQueryType(selectedType);
      setSelectedReportOption(selectedType);

      const currentConfig = reportConfigurations[selectedType] || {};

      const filteredValues = Object.keys(currentConfig).reduce((acc, key) => {
        if (newValues[key] !== undefined) {
          acc[key] = newValues[key];
        } else {
          acc[key] = currentConfig[key];
        }
        return acc;
      }, {});

      setFormValues(filteredValues);
    },
    [selectedQueryType]
  );

  useEffect(() => {
    if (Object.keys(formValues).length > 0) {
      setDynamicFormValues(formValues);
    }
  }, [formValues, setDynamicFormValues]);

  return (
    <>
      <DynamicForm sections={sections} options={reportOptions} onValuesChange={handleQueryTypeChange} />
    </>
  );
};

export default ReportConfigAccordion;
