import * as React from "react";
import { makeStyles, Link, Button } from "@fluentui/react-components";
import { QuestionCircle16Filled } from "@fluentui/react-icons";

const useStyles = makeStyles({
  footer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "grey",
    padding: "10px",
  },
  activity: {
    display: "flex",
    justifyContent: "space-between",
  },
  helpLink: {
    backgroundColor: "#9E9E9E",
    fontSize: "10px",
    fontStyle: "italic",
    padding: "3px 6px",
    color: "black",
    borderRadius: "10px",
    textDecoration: "none",
  },
  myActivity: {
    color: "#b521cf",
    "& button": {
      padding: "0",
    },
  },
});

const Footer = (props) => {
  const styles = useStyles();
  const { userName, logout, user } = props;
  return (
    <div id="footer" className={styles.footer}>
      <div className={styles.activity}>
        <span>{userName}</span>
        <span>(V.405)</span>
      </div>
      <div>
        <span className={styles.myActivity}>
          My activity
          <Button appearance="transparent" icon={<QuestionCircle16Filled />} />
        </span>
        <Link className={styles.helpLink} appearance="subtle" href="https://support.dataslayer.ai/">
          Help center
        </Link>
      </div>
      <span>
        Daily API calls {user?.current_usage} / {user?.account_limit === -1 ? "Unlimited" : user?.account_limit}
      </span>
      <div className="flex justify-between">
        <span>
          Plan:
          <strong> {user?.plan_name}</strong>
        </span>
        <Link onClick={logout}>Logout</Link>
      </div>
    </div>
  );
};

export default Footer;
