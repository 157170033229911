import * as React from "react";
import {
  makeStyles,
  Button,
  Menu,
  MenuTrigger,
  MenuButton,
  MenuPopover,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverSurface,
  Link,
} from "@fluentui/react-components";
import {
  WrenchFilled,
  CalendarMonthRegular,
  Info24Regular,
  SignOutFilled,
  WarningRegular,
} from "@fluentui/react-icons";
import { useQueryContext } from "../context/QueryContext";
import {
  handleDuplicateFile,
  handleReportQuery,
  handleUserRegister,
  manageQueries,
  showDialog,
} from "../../utilities/excel";
import { useQueryRefresh } from "../../utilities/useQueryRefresh";
import { useDialog } from "../context/DialogContext";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    width: "auto",
    height: "auto",
    boxSizing: "border-box",
    padding: "15px",
    gap: "15px",
    "> *": {
      textOverflow: "ellipsis",
    },
    "> :not(:first-child)": {
      marginTop: "0px",
    },
    "> *:not(.ms-StackItem)": {
      flexShrink: 1,
    },
  },
  headerButtons: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "auto",
    justifyContent: "space-around",
    gap: "10px",
  },
  manageQueries: {
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  menuButton: {
    alignItems: "flex-start",
    width: "100%",
  },
  linkLogout: {
    color: "white",
    fontWeight: "bold",
  },
});

const Header = ({ logout }) => {
  const styles = useStyles();
  const { loadQueriesFromSheet, queries, getSelectedQuery } = useQueryContext();
  const token = localStorage.getItem("DS-TOKEN");
  const userName = localStorage.getItem("DS-EXCEL-USERNAME");
  const { dialogOpen, setDialogOpen } = useDialog();
  const selectedQuery = getSelectedQuery();
  const { handleRefreshAll, handleRefreshActiveSheetQueries } = useQueryRefresh(
    setDialogOpen,
    loadQueriesFromSheet,
    token,
    userName
  );

  return (
    <div className={styles.root}>
      <div className={styles.headerButtons} id="header-buttons">
        {/* <Button className="hidden w-full" appearance="secondary" icon={<CalendarMonthRegular />}>
          Schedule
        </Button> */}
        <Menu className="w-full">
          <MenuTrigger disableButtonEnhancement>
            <MenuButton className={styles.menuButton} appearance="secondary" icon={<WrenchFilled />}>
              Tools
            </MenuButton>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              <MenuItem onClick={() => handleRefreshAll(queries)}>Refresh all queries</MenuItem>
              <MenuItem onClick={() => handleRefreshActiveSheetQueries(queries)}>Refresh active sheet</MenuItem>
              <MenuItem onClick={() => manageQueries()}>Manage queries</MenuItem>
              <MenuItem onClick={() => handleDuplicateFile()}>Duplicate this file</MenuItem>
              <MenuItem
                onClick={() => {
                  if (!selectedQuery) showDialog("Warning message", ["You do not have any parameters selected"]);
                  else handleReportQuery(token, selectedQuery);
                }}
              >
                Report an issue with a query
              </MenuItem>
              <MenuItem onClick={() => handleUserRegister()}>Open register</MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
        <Popover appearance="brand">
          <PopoverTrigger disableButtonEnhancement>
            <Button className="hidden w-full" appearance="secondary" icon={<Info24Regular />} />
          </PopoverTrigger>

          <PopoverSurface tabIndex={-1}>
            <div className="flex gap-2 justify-start items-center font-bold text-base">
              <WarningRegular />
              <h4 className="flex m-0">Important</h4>
            </div>
            {/* <h4 className="flex m-0 justify-start items-center">Important</h4> */}
            <p style={{ marginTop: "5px" }}>
              To ensure your data and account remain secure, please remember to{" "}
              <Link className={styles.linkLogout} onClick={logout}>
                log out of the add-in
              </Link>{" "}
              once you have finished your session. This will help protect your personal information and keep your
              account safe.
            </p>
          </PopoverSurface>
        </Popover>

        <Button className="hidden w-full" appearance="secondary" icon={<SignOutFilled />} onClick={logout} />
      </div>
    </div>
  );
};

export default Header;
