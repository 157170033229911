import { getUserTimeZone } from "./excel";
import { isValidDate, onFormatDate } from "./formatDates";

export const adaptConnectorData = (connectorKey, data) => {
  if (connectorKey.startsWith("blend_")) {
    return adaptBlendData(data);
  }
  return adaptStandardConnectorData(data);
};

export const adaptStandardConnectorData = (data) => {
  const { result, filters, options, order_dims, order_metrics } = data;
  return data;
};

export const adaptBlendData = (blendData) => {
  const blendResult = blendData.blend.result || {};
  const datasources = blendData.datasources || {};
  const blendFilters = blendData.blend.filters || [];
  const blendOptions = blendData.blend.options || [];

  let finalDimensions = [];
  let finalMetrics = [];

  // Procesar dimensiones y métricas del blend
  if (blendResult.dimensions && blendResult.metrics) {
    const blendDimensions = blendResult.dimensions.map((dim) => ({
      id: dim.id,
      name: dim.name,
      source: "blend",
      datasource_id: "blend",
    }));

    const blendMetrics = blendResult.metrics.map((metric) => ({
      id: metric.id,
      name: metric.name,
      source: "blend",
      datasource_id: "blend",
    }));

    finalDimensions = [...finalDimensions, ...blendDimensions];
    finalMetrics = [...finalMetrics, ...blendMetrics];
  }

  // Procesar dimensiones y métricas de cada datasource
  Object.entries(datasources).forEach(([datasource_id, source]) => {
    const { name, result } = source;
    if (result.dimensions && result.metrics) {
      const sourceDimensions = result.dimensions.map((dim) => ({
        id: dim.id,
        name: dim.name,
        source: name,
        datasource_id: datasource_id,
      }));

      const sourceMetrics = result.metrics.map((metric) => ({
        id: metric.id,
        name: metric.name,
        source: name,
        datasource_id: datasource_id,
      }));

      finalDimensions = [...finalDimensions, ...sourceDimensions];
      finalMetrics = [...finalMetrics, ...sourceMetrics];
    }
  });

  // Agrupar datos finales por source
  const groupedDimensions = groupBySource(finalDimensions);
  const groupedMetrics = groupBySource(finalMetrics);

  return {
    filters: blendFilters,
    options: blendOptions,
    result: {
      dimensions: groupedDimensions,
      metrics: groupedMetrics,
    },
  };
};

// Función para agrupar por source
const groupBySource = (items) => {
  return items.reduce((acc, item) => {
    const { source } = item;
    if (!acc[source]) {
      acc[source] = [];
    }
    acc[source].push(item);
    return acc;
  }, {});
};

export const transformAccounts = (data) => {
  const result = {};
  Object.entries(data).forEach(([key, value]) => {
    result[key] = {
      [value.name]: value.accounts.map((account) => {
        const transformedAccount = {
          id: account.id,
          name: account.name,
          connection_id: account.connection_id,
          datasource_id: key,
        };
        if (account.profile_id) {
          transformedAccount.profile_id = account.profile_id;
        }
        return transformedAccount;
      }),
    };
  });
  return result;
};

const otherAccountsTransform = (inputData) => {
  const outputData = {};
  for (const [connectionId, accounts] of Object.entries(inputData)) {
    const datasourceId = connectionId.split(" ")[0];

    outputData[datasourceId] = {
      Default: accounts.map((account) => ({
        id: account.id,
        name: account.name,
        profile_id: account.profile_id,
        connection_id: connectionId,
        datasource_id: datasourceId,
        country_code: account.country_code,
      })),
    };
  }

  return outputData;
};

export const transformAccountsWithConnectionIdAndEmail = (data) => {
  const result = {};
  Object.entries(data).forEach(([email, groups]) => {
    result[email] = {};
    Object.entries(groups).forEach(([groupName, accounts]) => {
      result[email][groupName] = accounts.map((account) => {
        const transformedAccount = {
          id: account.id,
          name: account.name,
          profile_id: account.profile_id,
          connection_id: email,
          email: email,
          advertiser_id: account.advertiser_id,
          account_name: account.account_name,
        };

        return transformedAccount;
      });
    });
  });
  return result;
};

export const processAccounts = (connectorKey, accounts) => {
  let processed = {};
  if (connectorKey.startsWith("blend_")) {
    processed = transformAccounts(accounts);
  } else if (
    [
      "amazon_ads",
      "applesearchads",
      "criteo",
      "facebook",
      "facebookinsights",
      "instagram",
      "klaviyo",
      "linkedin",
      "linkedinpages",
      "pinterest",
      "searchconsole",
      "stripe",
      "tiktok",
      "tiktok_insights",
      "twitter",
      "youtube",
    ].includes(connectorKey)
  ) {
    processed = otherAccountsTransform(accounts);
  } else if (connectorKey === "database") {
    processed = accounts;
  } else {
    processed = transformAccountsWithConnectionIdAndEmail(accounts);
  }
  return processed;
};

export const generateRequestBody = (connectorType, parameters) => {
  const strategies = {
    blend: generateBlendRequestBody,
    default: generateDefaultRequestBody,
  };

  const strategy = connectorType.startsWith("blend_") ? strategies.blend : strategies.default;

  return strategy(parameters);
};

export const generateBlendRequestBody = (params) => {
  const {
    dates,
    compareDates,
    appliedMetricsSorts,
    appliedDimsSorts,
    selectedAccounts,
    selectedDimsOptions,
    selectedDimsColsOptions,
    selectedMetricsOptions,
    limits,
    appliedFilters,
    dynamicFormValues,
    selectedSpecialSettings,
    selectedConnectors,
    entryConnector,
    config,
    token,
    userName,
    task_id = "",
    service_id = config.SERVICE_ID,
    row_index = 0,
    cache = true,
  } = params;
  return {
    task_id: task_id,
    service_id: service_id || config.SERVICE_ID,
    dates: {
      date_range_type: dates.date_range_type,
      start_date: isValidDate(dates.start_date) ? onFormatDate(dates.start_date) : dates.start_date,
      end_date: isValidDate(dates.end_date) ? onFormatDate(dates.end_date) : dates.end_date,
    },
    compare_dates:
      Object.keys(compareDates).length > 0 && isValidDate(compareDates.start_date) && isValidDate(compareDates.end_date)
        ? {
            to: compareDates.to,
            type: compareDates.type,
            start_date:
              Object.keys(compareDates).length === 0
                ? onFormatDate(compareDates.start_date)
                : onFormatDate(dates.start_date),
            end_date: compareDates ? onFormatDate(compareDates.end_date) : onFormatDate(dates.end_date),
          }
        : {},
    filters: appliedFilters || [],
    timezone: getUserTimeZone(),
    options: selectedSpecialSettings || {},
    sort_by: appliedMetricsSorts.map((sort) => [sort.metric, sort.order]),
    sort_dims_by: appliedDimsSorts.map((sort) => [sort.dim, sort.order]),
    output_type: dynamicFormValues?.output_type || "",
    real_output_type: dynamicFormValues?.real_output_type || "",
    dataslayer_token: token || "",
    high_priority: true,
    ip: "",
    row_index: row_index,
    col_alias: dynamicFormValues?.col_alias || {},
    metric_alias: dynamicFormValues?.metric_alias || {},
    row_alias: dynamicFormValues?.row_alias || {},
    query_id: dynamicFormValues?.query_id || "",
    query_type: dynamicFormValues?.query_type || "",
    schedule: dynamicFormValues?.schedule || true,
    trigger_id: dynamicFormValues?.trigger_id || "",
    account_name: dynamicFormValues?.account_name || "",
    api_info: dynamicFormValues?.api_info || {},
    app_script_name: dynamicFormValues?.app_script_name || "",
    app_script_version: dynamicFormValues?.app_script_version || 0,
    sheet_id: dynamicFormValues?.sheet_id || "",
    sheet_info: dynamicFormValues?.sheet_info || {},
    datasource_email: userName || "",
    creator_user_email: userName || "",
    user_account: dynamicFormValues?.user_account || "",
    query: dynamicFormValues?.query || "",
    collection: dynamicFormValues?.collection || "",
    project_id: dynamicFormValues?.project_id || "",
    call_type: dynamicFormValues?.call_type || "",
    page_ids: dynamicFormValues?.page_ids || "",
    number_of_posts: dynamicFormValues?.number_of_posts || "",
    usernames: dynamicFormValues?.usernames || "",
    num_posts_by_hashtag: dynamicFormValues?.num_posts_by_hashtag || 0,
    post_search_type: dynamicFormValues?.post_search_type || "",
    hashtags: dynamicFormValues?.hashtags || "",
    result_type_related: dynamicFormValues?.result_type_related || "",
    region: dynamicFormValues?.region || "",
    gprop: dynamicFormValues?.gprop || "",
    type: dynamicFormValues?.type || "",
    category: dynamicFormValues?.category || "",
    year: dynamicFormValues?.year || "",
    segments: dynamicFormValues?.segments || [],
    segment_dim: dynamicFormValues?.segment_dim || "",
    search_types: dynamicFormValues?.search_types || [],
    search_term: dynamicFormValues?.search_term || "",
    country: dynamicFormValues?.country || "",
    json_path: dynamicFormValues?.json_path || "",
    headers: dynamicFormValues?.headers || "",
    url: dynamicFormValues?.url || "",
    request: dynamicFormValues?.request || "",
    document_url: dynamicFormValues?.document_url || "",
    sheet_name: dynamicFormValues?.sheet_name || "",
    sheet_range: dynamicFormValues?.sheet_range || "",
    cache: cache,
    connections:
      selectedConnectors && entryConnector && selectedConnectors[entryConnector]
        ? selectedConnectors[entryConnector].map((connection) => ({
            id: connection.id || "",
            datasource_id: connection.datasource_id || "",
            datasource_name: connection.datasource_name || "",
          }))
        : [],
    accounts: selectedAccounts || [],
    metrics: selectedMetricsOptions.map((metric) => ({
      id: metric.id,
      datasource_id: metric.datasource_id || "",
    })),
    dimensions: {
      rows:
        selectedDimsOptions.map((dim) => ({
          id: dim.id,
          datasource_id: dim.datasource_id || "",
        })) || [],

      cols:
        selectedDimsColsOptions.map((dim) => ({
          id: dim.id,
          datasource_id: dim.datasource_id || "",
        })) || [],

      limit_cols: limits.limitCols || 0,
      limit_rows: limits.limitRows || 0,
    },
  };
};

// Función que genera el requestBody por defecto para otros conectores
export const generateDefaultRequestBody = (params) => {
  const {
    dates,
    compareDates,
    appliedMetricsSorts,
    appliedDimsSorts,
    selectedAccounts,
    selectedDimsOptions,
    selectedDimsColsOptions,
    selectedMetricsOptions,
    limits,
    appliedFilters,
    dynamicFormValues,
    selectedSpecialSettings,
    selectedConnectors,
    entryConnector,
    config,
    selectedReportOption,
  } = params;
  return {
    dates: {
      date_range_type: dates.date_range_type,
      start_date: isValidDate(dates.start_date) ? onFormatDate(dates.start_date) : dates.start_date,
      end_date: isValidDate(dates.end_date) ? onFormatDate(dates.end_date) : dates.end_date,
    },
    timezone: getUserTimeZone(),
    sort_by: appliedMetricsSorts.map((sort) => [sort.metric, sort.order]),
    sort_dims_by: appliedDimsSorts.map((sort) => [sort.dim, sort.order]),
    options: selectedSpecialSettings || {},
    high_priority: true,
    compare_dates:
      compareDates && Object.keys(compareDates).length > 0 && isValidDate(compareDates.start_date) && isValidDate(compareDates.end_date)
        ? {
            to: compareDates.to,
            type: compareDates.type,
            start_date: onFormatDate(compareDates.start_date),
            end_date: onFormatDate(compareDates.end_date),
          }
        : {},
    service_id: config.SERVICE_ID,
    accounts: selectedAccounts.map((account) => ({
      connection_id: account.connection_id || "",
      id: account.id || "",
      email: account.email || "",
      profile_id: account.profile_id || "",
      name: account.name?.toString() || "",
      country_code: account.country_code || "",
      advertiser_id: account.advertiser_id || "",
      account_name: account.account_name || "",
    })),
    metrics: selectedMetricsOptions.map((metric) => metric.id),
    dimensions: {
      rows: selectedDimsOptions.map((dim) => dim.id),
      cols: selectedDimsColsOptions.map((dim) => dim.id),
      limit_rows: limits.limitRows,
      limit_cols: limits.limitCols,
    },
    filters: appliedFilters,
    sheet_name: dynamicFormValues?.sheet_name || "",
    sheet_range: dynamicFormValues?.sheet_range || "",
    document_url: dynamicFormValues?.document_url || "",
    call_type: selectedReportOption || "",
    num_posts_by_hashtag: dynamicFormValues?.num_posts_by_hashtag || 0,
    hashtags: dynamicFormValues?.hashtags || "",
    usernames: dynamicFormValues?.usernames || "",
    post_search_type: dynamicFormValues?.post_search_type || "",
    url: dynamicFormValues?.url || "",
    request: dynamicFormValues?.request || "",
    type: dynamicFormValues?.type || "",
    headers: dynamicFormValues?.headers || "",
    json_path: dynamicFormValues?.json_path || "",
    query: dynamicFormValues?.query || "",
    connections:
      selectedConnectors && entryConnector && selectedConnectors[entryConnector]
        ? selectedConnectors[entryConnector].map((connection) => ({
            id: connection.id,
          }))
        : [],
    page_ids: dynamicFormValues?.page_ids || "",
    number_of_posts: dynamicFormValues?.number_of_posts || "",
    gprop: dynamicFormValues?.gprop || "",
    category: dynamicFormValues?.category || "",
    search_term: dynamicFormValues?.search_term || "",
    region: dynamicFormValues?.region || "",
    country: dynamicFormValues?.region || "",
    year: dynamicFormValues?.year || "",
  };
};
