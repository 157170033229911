import * as React from "react";
import { makeStyles, Spinner } from "@fluentui/react-components";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import { HashRouter as Router } from "react-router-dom";

import { logoutFromO365, signInO365 } from "../../utilities/office-apis-helpers";
import { getDStoken, getUserInfo } from "../../services/login";

import Content from "./Content";
import Header from "./Header";
import Footer from "./Footer";
import StartPage from "./StartPageLogin";
import { config } from "../../Constants";
import { handleDuplicateFile, handleGettingStarted, manageQueries } from "../../utilities/excel";
import { useQueryRefresh } from "../../utilities/useQueryRefresh";
import { useDialog } from "../context/DialogContext";
import { useQueryContext } from "../context/QueryContext";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

const App = (props) => {
  const styles = useStyles();

  const storedToken = localStorage.getItem("DS-EXCEL-TOKEN");
  const storedDSToken = localStorage.getItem("DS-TOKEN");
  const storedUserName = localStorage.getItem("DS-EXCEL-USERNAME");
  const [authStatus, setAuthStatus] = React.useState("loginInProcess");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [token, setToken] = React.useState(storedToken);
  const [DSToken, setDSToken] = React.useState(storedDSToken);
  const [userName, setUserName] = React.useState(storedUserName);
  const { dialogOpen, setDialogOpen } = useDialog();
  const { loadQueriesFromSheet, queries, getSelectedQuery } = useQueryContext();
  const { handleRefreshAll, handleRefreshActiveSheetQueries } = useQueryRefresh(
    setDialogOpen,
    loadQueriesFromSheet,
    DSToken,
    userName
  );
  const displayError = (error) => {
    setErrorMessage(error);
  };
  React.useEffect(() => {
    const handleRibbonClick = (event) => {
      const { action } = event.detail;

      switch (action) {
        case "manageQueries":
          manageQueries();
          break;
        case "refreshAllQueries":
          handleRefreshAll(queries);
          break;
        case "refreshActiveSheet":
          handleRefreshActiveSheetQueries(queries);
          break;
        case "duplicateFile":
          handleDuplicateFile();
          break;
        case "templateGallery":
          handleDuplicateFile();
          break;
        case "gettingStarted":
          handleGettingStarted();
          break;
        default:
          break;
      }
    };

    window.addEventListener("ribbonButtonClick", handleRibbonClick);

    return () => {
      window.removeEventListener("ribbonButtonClick", handleRibbonClick);
    };
  }, [manageQueries, handleRefreshAll, handleRefreshActiveSheetQueries]);

  React.useEffect(() => {
    const token = localStorage.getItem("DS-TOKEN");
    if (token) {
      setDSToken(token);
    }
  }, []);

  const login = async () => {
    await signInO365(setAuthStatus, setToken, setUserName, displayError, setDSToken);
  };

  const logout = async () => {
    await logoutFromO365(setAuthStatus, setUserName, userName, displayError);
  };

  const { data, refetch } = useQuery(
    ["dsToken", token],
    async () => {
      const response = await getDStoken({ token });
      if (response?.ok) return await response.json();
      throw new Error("Failed to authenticate with DS API");
    },
    {
      staleTime: Infinity,
      enabled: !!token,
      onSuccess: (data) => {
        setAuthStatus("loggedIn");
        setDSToken(data.token);
        localStorage.setItem("DS-TOKEN", data.token);
      },
      onError: (error) => {
        setAuthStatus("notLoggedIn");
        setErrorMessage(error.message);
        displayError("Failed to authenticate with DS API");
      },
    }
  );

  const { data: user } = useQuery(
    ["user-info", DSToken],
    async () => {
      const response = await getUserInfo({
        token: DSToken,
        service_id: config.SERVICE_ID,
      });
      if (response?.ok) return await response.json();
      throw new Error("Error fetching models");
    },
    { staleTime: Infinity, enabled: !!DSToken }
  );

  React.useEffect(() => {
    if (!storedToken || !storedUserName) {
      login();
    }
  }, []);

  let body;

  if (authStatus === "notLoggedIn") {
    body = <StartPage login={login} />;
  } else if (authStatus === "loginInProcess") {
    body = (
      <div className="flex flex-col justify-center h-screen">
        <Spinner labelPosition="below" label="Please sign-in on the pop-up window" />;
      </div>
    );
  } else {
    body = (
      <>
        <Header title={props.title} />
        <Router basename="/">
          <Content />
        </Router>
        <Footer userName={userName} user={user} logout={logout} />
      </>
    );
  }

  return (
    <>
      {errorMessage ? <div>{errorMessage}</div> : null}
      <div className={styles.root}>{body}</div>;
    </>
  );
};

App.propTypes = {
  title: PropTypes.string,
};

export default App;
