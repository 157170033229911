import React from "react";
import {
  Spinner,
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  makeStyles,
  DialogTrigger,
  Button,
} from "@fluentui/react-components";
import { DeleteFilled } from "@fluentui/react-icons";
const useStyles = makeStyles({
  spinner: {
    marginTop: "20px",
    justifyContent: "start",
    gridArea: "2 / 1 / 2 / 4",
  },
  dialogWidth: {
    width: "80%",
    maxWidth: "500px",
    margin: "auto",
  },
  dialogContent: {
    gridArea: "2 / 1 / 2 / 4",
  },
});
const RunningDialog = ({ dialogOpen, taskId, handleCancelTaskClick }) => {
  const styles = useStyles();
  return (
    <Dialog open={dialogOpen}>
      <DialogSurface className={styles.dialogWidth}>
        <DialogBody>
          <DialogTitle
            action={
              taskId ? (
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<DeleteFilled />}
                    onClick={handleCancelTaskClick}
                  />
                </DialogTrigger>
              ) : null
            }
          >
            Running query
          </DialogTitle>
          <Spinner className={styles.spinner} labelPosition="before" size="tiny" label="Waiting for the response" />
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default RunningDialog;
