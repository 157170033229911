import React from "react";
import { Spinner, Dialog, DialogSurface, DialogBody, DialogTitle, makeStyles } from "@fluentui/react-components";
const useStyles = makeStyles({
  spinner: {
    marginTop: "20px",
    justifyContent: "start",
  },
  dialogWidth: {
    width: "80%",
    maxWidth: "500px",
    margin: "auto",
  },
  dialogBody: {
    display: "flex!important",
    flexDirection: "column!important",
  },
});
const RunningDialog = ({ dialogOpen }) => {
  const styles = useStyles();
  return (
    <Dialog open={dialogOpen}>
      <DialogSurface className={styles.dialogWidth}>
        <DialogBody className={styles.dialogBody}>
          <DialogTitle>Running query</DialogTitle>
          <Spinner className={styles.spinner} labelPosition="before" size="tiny" label="Waiting for the response" />
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default RunningDialog;
