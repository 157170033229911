import React, { createContext, useState, useContext } from "react";

const DialogContext = createContext();

export const useDialog = () => useContext(DialogContext);

export const DialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return <DialogContext.Provider value={{ dialogOpen, setDialogOpen }}>{children}</DialogContext.Provider>;
};
