import React from "react";
import { useMutation } from "react-query";
import { cleanDate } from "./formatDates";
import { getUserTimeZone, showDialog, updateQuerySheet } from "./excel";
import { config } from "../Constants";
import { generateBlendTable, generateTable, getAccountsByTaskId } from "../services/connector";
import { useQueryContext } from "../taskpane/context/QueryContext";

export const useQueryRefresh = (setDialogOpen, loadQueriesFromSheet, token, userName) => {
  const startTimeRef = React.useRef(null);
  const { refreshStatusQuery } = useQueryContext();
  const refreshTableMutation = useMutation(
    async (requestData) => {
      const response = await generateTable({
        datasource: requestData.dataSource,
        token: token,
        email: userName,
        creatorUserEmail: userName,
        previewData: false,
        requestData: requestData,
      });
      if (response?.ok) return await response.json();
      throw new Error("Error generating table");
    },
    {
      onError: (error) => {
        showDialog("Warning message", "There is no data for the specified input");
        setDialogOpen(false);
      },
    }
  );

  const refreshBlendTableMutation = useMutation(
    async (requestData) => {
      const response = await generateBlendTable({
        hash_id: requestData.dataSource,
        token: token,
        email: userName,
        creatorUserEmail: userName,
        previewData: false,
        requestData: requestData,
      });
      if (response?.ok) return await response.json();
      throw new Error("Error generating blend table");
    },
    {
      onError: (error) => {
        showDialog("Warning message", "There is no data for the specified input");
        setDialogOpen(false);
      },
    }
  );

  // Función para refrescar un solo query
  const handleRefresh = async (query, queries) => {
    try {
      setDialogOpen(true);
      const queryData = getQueryConfigById(query, queries);
      const requestData = transformQueryDataForAPI(queryData);
      startTimeRef.current = performance.now();

      let result;
      if (requestData.dataSource.startsWith("blend")) {
        result = await refreshBlendTableMutation.mutateAsync(requestData);
      } else {
        result = await refreshTableMutation.mutateAsync(requestData);
      }

      if (result.ok) {
        const taskId = result.task_id;
        const data = await fetchTableData(taskId);
        if (data) {
          const executionTime = ((performance.now() - startTimeRef.current) / 1000).toFixed(2);
          updateQuerySheet(query, executionTime, refreshStatusQuery, data.result);
        }
      }
    } catch (err) {
      showDialog("Error", ["Failed to refresh query"]);
    } finally {
      setDialogOpen(false);
      loadQueriesFromSheet();
    }
  };

  // Función para refrescar todos los queries secuencialmente
  const handleRefreshAll = async (queries) => {
    for (const query of queries) {
      await handleRefresh(query, queries);
    }
  };

  // Función para obtener datos de la tabla usando el task_id
  const fetchTableData = async (taskId) => {
    try {
      const response = await getAccountsByTaskId({ taskId });
      if (response?.ok) {
        const result = await response.json();
        if (result.finished) {
          return result;
        } else {
          return new Promise((resolve) => setTimeout(async () => resolve(await fetchTableData(taskId)), 3000));
        }
      } else {
        throw new Error("Error fetching accounts");
      }
    } catch (error) {
      showDialog("Warning message", "Failed to fetch accounts");
      return null;
    }
  };

  function transformQueryDataForAPI(queryData) {
    const isBlendConnector = queryData.dataSource.startsWith("blend");
    return {
      ...queryData,
      dataSource: queryData.dataSource,
      dates: {
        end_date: cleanDate(queryData.endDate),
        start_date: cleanDate(queryData.startDate),
        date_range_type: queryData.dateRangeType,
      },
      timezone: getUserTimeZone(),
      accounts: JSON.parse(queryData.accountsViews),
      accountsViews: JSON.parse(queryData.accountsViews),
      metrics: isBlendConnector
        ? JSON.parse(queryData.metrics)
        : JSON.parse(queryData.metrics).map((metric) => metric.id),
      pivotDimensions: JSON.parse(queryData.pivotDimensions),
      dims: JSON.parse(queryData.dimensions),
      filters: JSON.parse(queryData.filters),
      refreshWithUserAccount: JSON.parse(queryData.refreshWithUserAccount),
      sort_by: JSON.parse(queryData.sort),
      sort_dims_by: JSON.parse(queryData.sortDimensions),
      options: {},
      high_priority: true,
      compare_dates: {},
      service_id: config.SERVICE_ID,
      connections: JSON.parse(queryData.refreshWithUserAccount),
      dimensions: {
        rows: isBlendConnector
          ? JSON.parse(queryData.dimensions)
          : JSON.parse(queryData.dimensions).map((dim) => dim.id),

        cols: isBlendConnector
          ? JSON.parse(queryData.pivotDimensions)
          : JSON.parse(queryData.pivotDimensions).map((dim) => dim.id),

        limit_rows: queryData.maxRows || 0,
        limit_cols: queryData.maxPivotCategories || 0,
      },
      sheet_name: JSON.parse(queryData.otherParameters).sheet_name || "",
      sheet_range: JSON.parse(queryData.otherParameters).sheet_range || "",
      document_url: JSON.parse(queryData.otherParameters).document_url || "",
    };
  }

  function getQueryConfigById(queryId, queries) {
    return queries.find((query) => query.queryUUID === queryId.queryUUID);
  }

  const handleRefreshActiveSheetQueries = async (queries) => {
    try {
      await Excel.run(async (context) => {
        const activeSheet = context.workbook.worksheets.getActiveWorksheet();
        activeSheet.load("name");
        await context.sync();

        const activeSheetName = activeSheet.name;
        const dataslayerQueriesSheet = context.workbook.worksheets.getItem("DataslayerQueries");
        const range = dataslayerQueriesSheet.getUsedRange();
        range.load("values");
        await context.sync();

        const matchingQueries = range.values.filter((row) => row[1] === activeSheetName);

        const activeSheetQueryIds = matchingQueries.map((row) => row[0]);
        const queriesToRefresh = queries.filter((query) => activeSheetQueryIds.includes(query.queryUUID));

        for (const query of queriesToRefresh) {
          await handleRefresh(query, queries);
        }
      });
    } catch (error) {
      showDialog("Error", "Failed to refresh active sheet queries");
    }
  };

  return {
    handleRefresh,
    handleRefreshAll,
    handleRefreshActiveSheetQueries,
  };
};
