import { fetchAPI } from "./base";

export function getConnectors({ token }) {
  return fetchAPI({
    endpoint: `hub/login`,
    token,
  });
}

export function getAccountByDataSource({ token, datasource, connections }) {
  return fetchAPI({
    endpoint: `datasources/account/get_accounts/${datasource}`,
    token,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ connections }),
  });
}

export function getAccountsByBlend({ token, hash_id, service_id, connections }) {
  const params = new URLSearchParams({ hash_id });
  if (service_id) {
    params.append("service_id", service_id);
  }
  const queryString = params.toString();
  return fetchAPI({
    endpoint: `blend/get_accounts?${queryString}`,
    token,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ connections }),
  });
}

export function getDataTables({ token, connections }) {
  return fetchAPI({
    endpoint: `datasources/database/get_tables`,
    token,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ connections }),
  });
}

export function getAccountsByTaskId({ taskId, signal }) {
  return fetchAPI({
    endpoint: `datasources/tasks/accounts?task_id=${taskId}`,
    signal,
  });
}

export function getMetrics({ entryConnector, token, call_type, service_id, is_blend }) {
  const params = new URLSearchParams({ datasource: entryConnector });
  if (call_type) {
    params.append("call_type", call_type);
  }
  if (service_id) {
    params.append("service_id", service_id);
  }
  if (is_blend) {
    params.append("is_blend", is_blend);
  }
  const queryString = params.toString();
  return fetchAPI({
    endpoint: `datasources/get_metrics?${queryString}`,
    token,
  });
}

export function generateTable({ datasource, token, email, creatorUserEmail, previewData = false, requestData }) {
  const params = new URLSearchParams({
    email: email,
    creator_user_email: creatorUserEmail,
    preview_data: previewData,
  });

  return fetchAPI({
    endpoint: `datasources/generate_table/${datasource}?${params.toString()}`,
    token,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(requestData),
  });
}

export function addIssue(token, requestData, comment) {
  const body = {
    json_query: requestData,
    datasource: requestData.dataSource,
    comment: comment,
  };
  return fetchAPI({
    endpoint: `datasources/add_issue`,
    token,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function getLoginInfo(email, service_id, datasource) {
  const body = {
    email: email,
    datasource: datasource || "",
    service_id: service_id,
  };
  return fetchAPI({
    endpoint: `datasources/login`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  });
}

export function deleteTaskId({ taskId }) {
  return fetchAPI({
    endpoint: `datasources/delete_task/${taskId}`,
  });
}

export function getBlendMetrics({ hash_id, token, service_id }) {
  const params = new URLSearchParams({ hash_id });

  if (service_id) {
    params.append("service_id", service_id);
  }

  const queryString = params.toString();
  return fetchAPI({
    endpoint: `blend/get_metrics?${queryString}`,
    token,
  });
}

export function generateBlendTable({ hash_id, token, email, creatorUserEmail, requestData }) {
  const params = new URLSearchParams({
    hash_id: hash_id,
    email: email,
    creator_user_email: creatorUserEmail,
  });
  return fetchAPI({
    endpoint: `blend/generate_table?${params.toString()}`,
    token,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(requestData),
  });
}