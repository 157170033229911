import React from "react";
import { Routes, Route } from "react-router-dom";
import QueryManager from "./QueryManager";
import MainAccordion from "./MainAccordion";

const Content = ({ ...props }) => {
  return (
    <div id="main-content" className="mb-20">
      <Routes>
        <Route exact path="/" element={<QueryManager />} />
        <Route path="add-query" element={<MainAccordion title="CONNECTORS" />} />
      </Routes>
    </div>
  );
};

export default Content;
