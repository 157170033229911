import * as React from "react";
import { makeStyles, Button, Image, typographyStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  startBody: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    padding: "50px",
  },
  text: typographyStyles.title2,
  explain: {
    ...typographyStyles.body1,
    textAlign: "center",
  },
});

const StartPage = (props) => {
  const styles = useStyles();
  const { login } = props;
  return (
    <div id="start-body" className={styles.startBody}>
      <div>
        <Image alt="Dataslayer Logo" src="../assets/dataslayer-logo.svg" height={50} width={200} />
      </div>
      <div className="flex flex-col items-center">
        <span className={styles.text}>Sign in to Dataslayer</span>
        <span className={styles.explain}>Please log in to continue enjoying the Dataslayer experience in Excel</span>
      </div>
      <div>
        <Button className="w-full" appearance="primary" onClick={login}>
          Sign in
        </Button>
      </div>
    </div>
  );
};

export default StartPage;
