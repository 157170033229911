import { config } from "../Constants";
import { addIssue } from "../services/connector";
import { cleanDate, formatDateTime } from "./formatDates";

//Utilities

let dialog;

export function showDialog(title, error) {
  const dialogData = {
    title: title,
    errors: error,
  };

  localStorage.setItem("dialogData", JSON.stringify(dialogData));
  Office.context.ui.displayDialogAsync(
    "https://test-excel-addin.dataslayer.ai/popup/popup.html",
    { height: 60, width: 50 },
    function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
      } else {
        dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, function (args) {
          if (args.message === "requestData") {
            const storedData = localStorage.getItem("dialogData");
            dialog.messageChild(storedData);
          } else if (args.message === "close") {
            dialog.close();
            localStorage.removeItem("dialogData");
          }
        });
      }
    }
  );
}

export function openReportPopup(queryToReport) {
  Office.context.ui.displayDialogAsync(
    "https://test-excel-addin.dataslayer.ai/popup/reportPopup.html",
    { height: 60, width: 50 },
    function (asyncResult) {
      let dialog = asyncResult.value;

      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        console.error("Error opening dialog:", asyncResult.error.message);
      } else {
        // Listen for messages from the popup
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, function (arg) {
          const issueDescription = arg.message;
          submitIssueToBackend(queryToReport, issueDescription);
          dialog.close();
        });
      }
    }
  );
}

function transformQueryDataForAPI(queryData) {
  return {
    ...queryData,
    // dataSource: queryData.dataSource,
    dates: {
      end_date: cleanDate(queryData.endDate),
      start_date: cleanDate(queryData.startDate),
      date_range_type: queryData.dateRangeType,
    },
    timezone: getUserTimeZone(),
    accounts: JSON.parse(queryData.accountsViews),
    accountsViews: JSON.parse(queryData.accountsViews),
    metrics: JSON.parse(queryData.metrics),
    pivotDimensions: JSON.parse(queryData.pivotDimensions),
    dims: JSON.parse(queryData.dimensions),
    filters: JSON.parse(queryData.filters),
    refreshWithUserAccount: JSON.parse(queryData.refreshWithUserAccount),
    sort_by: JSON.parse(queryData.sort),
    sort_dims_by: JSON.parse(queryData.sortDimensions),
    options: JSON.parse(queryData.specialSettings),
    high_priority: true,
    compare_dates: {},
    service_id: config.SERVICE_ID,
    dimensions: {
      rows: JSON.parse(queryData.dimensions).map((dim) => dim.id),
      cols: JSON.parse(queryData.pivotDimensions).map((dim) => dim.id),
      limit_rows: queryData.maxRows,
      limit_cols: queryData.maxPivotCategories,
    },
  };
}

export const handleDuplicateFile = () => {
  Office.context.ui.displayDialogAsync(
    "https://test-excel-addin.dataslayer.ai/popup/duplicateFile.html",
    { height: 60, width: 40 },
    function (asyncResult) {
      const dialog = asyncResult.value;

      dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (args) => {
        const messageFromPopup = JSON.parse(args.message);

        if (messageFromPopup.action === "submit") {
          // accion
        } else if (messageFromPopup === "close") {
          dialog.close();
        }
      });
    }
  );
};

export const handleGettingStarted = () => {
  Office.context.ui.displayDialogAsync(
    "https://test-excel-addin.dataslayer.ai/popup/gettingStarted.html",
    { height: 80, width: 60 },
    function (asyncResult) {
      const dialog = asyncResult.value;

      dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (args) => {
        const messageFromPopup = JSON.parse(args.message);

        if (messageFromPopup.action === "submit") {
          // accion
        } else if (messageFromPopup === "close") {
          dialog.close();
        }
      });
    }
  );
};

export const handleReportQuery = (token, query) => {
  Office.context.ui.displayDialogAsync(
    "https://test-excel-addin.dataslayer.ai/popup/reportPopup.html",
    { height: 60, width: 40 },
    function (asyncResult) {
      const dialog = asyncResult.value;

      dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (args) => {
        const messageFromPopup = JSON.parse(args.message);

        if (messageFromPopup.action === "submit") {
          const queryDataToReport = transformQueryDataForAPI(query);

          try {
            await addIssue(token, queryDataToReport, messageFromPopup.description);

            dialog.close();

            setTimeout(() => {
              showDialog("Report Sent", ["Your issue has been reported successfully."]);
            }, 500);
          } catch (error) {
            console.error("Error sending issue:", error);
            dialog.close();

            setTimeout(() => {
              showDialog("Error", ["There was an error sending the issue. Please try again."]);
            }, 500);
          }
        } else if (messageFromPopup === "close") {
          dialog.close();
        }
      });
    }
  );
};
export function generateUUID() {
  return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getUserTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

// Cargar y sincronizar la hoja de cálculo
async function loadWorksheet(context, sheetName) {
  const sheet = context.workbook.worksheets.getItem(sheetName);
  const range = sheet.getUsedRange();
  range.load("values");
  await context.sync();
  return { sheet, range, values: range.values };
}

// Buscar una fila por queryId
function findQueryRow(values, queryId) {
  for (let i = 0; i < values.length; i++) {
    if (values[i][0] === queryId) {
      return i;
    }
  }
  return -1;
}

// Obtener la fecha formateada en la zona horaria del usuario
function getFormattedCurrentDate() {
  const timeZone = getUserTimeZone();
  return formatDateTime(new Date(), timeZone, "yyyy-MM-dd HH:mm:ss");
}

// Encontrar el rango para actualizar una query existente
async function findRangeForUpdate(context, queryUUID) {
  const { sheet, values } = await loadWorksheet(context, "DataslayerQueries");
  const rowIndex = findQueryRow(values, queryUUID);

  if (rowIndex === -1) {
    console.error("Query ID no encontrado en la hoja DataslayerQueries");
    return null;
  }

  // Usamos getQueryInfo para obtener sheetName y tableRange
  const { sheetName, tableRange } = getQueryInfo(values, rowIndex);
  const startCell = tableRange.split(":")[0];
  const targetSheet = context.workbook.worksheets.getItem(sheetName);
  const selectedRange = targetSheet.getRange(startCell);

  return { selectedRange, sheetName };
}

// Obtener el rango seleccionado o el rango de inicio predeterminado
function getSelectedOrStartRange(context, sheet, startCell) {
  let selectedRange = sheet.getRange(startCell);

  if (context.workbook.getSelectedRange) {
    selectedRange = context.workbook.getSelectedRange();
  }

  return selectedRange;
}

// Construir los datos de la query para registrar o actualizar
function buildQueryData(params) {
  const { queryUUID, sheetName, rangeAddress, currentDate, queryRequest, connector, executionTime, isUpdate } = params;
  return {
    queryUUID,
    sheetName,
    rangeAddress,
    created: isUpdate
      ? formatDateTime(new Date(queryRequest.created), getUserTimeZone(), "yyyy-MM-dd HH:mm:ss")
      : currentDate,
    updated: isUpdate ? currentDate : "",
    lastStatus: isUpdate ? "Refreshed successfully" : "Created successfully",
    lastRefreshID: "",
    resultsContainSampledData: "",
    executionTime: parseInt(executionTime),
    objectType: "TABLE",
    linkedChartID: "",
    dataSource: connector,
    dateRangeType: queryRequest.dateRangeType,
    startDate:
      queryRequest.startDate && formatDateTime(new Date(queryRequest.startDate), getUserTimeZone(), "yyyy-MM-dd"),
    endDate: queryRequest.endDate && formatDateTime(new Date(queryRequest.endDate), getUserTimeZone(), "yyyy-MM-dd"),
    compareTo: "",
    comparisonValueType: "",
    accountsViews: isUpdate ? queryRequest.accountsViews : JSON.stringify(queryRequest.accountsViews),
    metrics: isUpdate ? queryRequest.metrics : JSON.stringify(queryRequest.metrics),
    dimensions: isUpdate ? queryRequest.dimensions : JSON.stringify(queryRequest.dimensions),
    pivotDimensions: isUpdate ? queryRequest.pivotDimensions : JSON.stringify(queryRequest.pivotDimensions),
    filters: isUpdate ? queryRequest.filters : JSON.stringify(queryRequest.filters),
    segmentID: "",
    segmentDim: "",
    sort: isUpdate ? queryRequest.sort_by : JSON.stringify(queryRequest.sort_by),
    maxRows: queryRequest.maxRows,
    maxPivotCategories: queryRequest.maxPivotCategories,
    specialSettings: isUpdate ? queryRequest.options : JSON.stringify(queryRequest.options),
    otherParameters: isUpdate ? queryRequest.otherParameters : JSON.stringify(queryRequest.otherParameters),
    resultType: "",
    languageCountry: "",
    translateTo: "",
    sql: "",
    databaseName: "",
    rangeAddressStatic: "",
    reportType: queryRequest.reportType,
    highlightWithColour: "",
    refreshWithUserAccount: isUpdate
      ? queryRequest.refreshWithUserAccount
      : JSON.stringify(queryRequest.refreshWithUserAccount),
    returnedData: "",
    sortDimensions: isUpdate ? queryRequest.sort_dims_by : JSON.stringify(queryRequest.sort_dims_by),
    createdByUserAccount: localStorage.getItem("DS-EXCEL-USERNAME"),
    blendName: "",
  };
}

// Obtener o crear una hoja
async function getOrCreateSheet(context, sheetName) {
  let sheet;
  try {
    sheet = context.workbook.worksheets.getItem(sheetName);
  } catch (error) {
    sheet = context.workbook.worksheets.add(sheetName);
  }
  sheet.load("name");
  await context.sync();
  return sheet;
}

// Obtener la última fila utilizada en la hoja
async function getLastRow(context, sheet) {
  const usedRange = sheet.getUsedRange();
  usedRange.load(["rowIndex", "rowCount"]);
  await context.sync();
  return usedRange.rowIndex + usedRange.rowCount;
}

// Función para obtener información de la query (nombre de la hoja y rango)
function getQueryInfo(values, rowIndex) {
  return {
    sheetName: values[rowIndex][1],
    tableRange: values[rowIndex][2],
  };
}

// Función para eliminar la tabla de la hoja de destino
async function deleteTableFromSheet(context, sheetName, tableRange) {
  try {
    const targetSheet = context.workbook.worksheets.getItem(sheetName);
    const tableRangeToDelete = targetSheet.getRange(tableRange);
    tableRangeToDelete.clear();
    await context.sync();
  } catch (error) {
    console.error(`No se pudo eliminar la tabla en la hoja ${sheetName} con rango ${tableRange}:`, error);
  }
}

// Función para eliminar la fila de la hoja DataSlayerQueries
async function deleteRowFromSheet(sheet, rowIndex) {
  if (rowIndex !== -1) {
    sheet.getRange(`${rowIndex + 1}:${rowIndex + 1}`).delete(Excel.DeleteShiftDirection.up);
  }
}

// Construir una nueva fila de datos para registrar una query
function buildQueryRow(queryData) {
  return [
    queryData.queryUUID || "",
    queryData.sheetName || "",
    queryData.rangeAddress || "",
    queryData.created || "",
    queryData.updated || "",
    queryData.lastStatus || "",
    queryData.lastRefreshID || "",
    queryData.resultsContainSampledData || "",
    queryData.executionTime || "",
    queryData.objectType || "",
    queryData.linkedChartID || "",
    queryData.dataSource || "",
    queryData.dateRangeType || "",
    queryData.startDate || "",
    queryData.endDate || "",
    queryData.compareTo || "",
    queryData.comparisonValueType || "",
    queryData.accountsViews || "",
    queryData.metrics || "",
    queryData.dimensions || "",
    queryData.pivotDimensions || "",
    queryData.filters || "",
    queryData.segmentID || "",
    queryData.segmentDim || "",
    queryData.sort || "",
    queryData.maxRows || "",
    queryData.maxPivotCategories || "",
    queryData.specialSettings || "",
    queryData.otherParameters || "",
    queryData.resultType || "",
    queryData.languageCountry || "",
    queryData.translateTo || "",
    queryData.sql || "",
    queryData.databaseName || "",
    queryData.rangeAddressStatic || "",
    queryData.reportType || "",
    queryData.highlightWithColour || "",
    queryData.refreshWithUserAccount || "",
    queryData.returnedData || "",
    queryData.sortDimensions || "",
    queryData.createdByUserAccount || "",
    queryData.blendName || "",
  ];
}

function convertToAbsoluteRange(rangeAddress) {
  if (rangeAddress.includes("!")) {
    rangeAddress = rangeAddress.split("!")[1];
  }
  rangeAddress = rangeAddress.replace(/([A-Z]+)(\d+)/g, "$$$1$$$2");
  return rangeAddress;
}

//Event to subscribe to catch cells and queries
export async function subscribeToSelectionChange(onCellSelectionChanged) {
  try {
    await Excel.run(async (context) => {
      const sheets = context.workbook.worksheets;
      sheets.load("items");
      await context.sync();

      sheets.items.forEach((sheet) => {
        sheet.onSelectionChanged.add(onCellSelectionChanged);
      });

      await context.sync();
    });
  } catch (error) {
    console.error("Error al suscribirse al evento de selección de celda:", error);
  }
}

export async function unsubscribeFromSelectionChange(onCellSelectionChanged) {
  try {
    await Excel.run(async (context) => {
      const sheets = context.workbook.worksheets;
      sheets.load("items");
      await context.sync();

      sheets.items.forEach((sheet) => {
        sheet.onSelectionChanged.remove(onCellSelectionChanged);
      });

      await context.sync();
    });
  } catch (error) {
    console.error("Error al eliminar la suscripción al evento de selección de celda:", error);
  }
}

export async function onCellSelectionChanged(eventArgs, queries, selectQueryInList) {
  try {
    await Excel.run(async (context) => {
      const sheet = context.workbook.worksheets.getItem(eventArgs.worksheetId);
      const range = sheet.getRange(eventArgs.address);

      range.load("address");
      sheet.load("name");
      await context.sync();

      const selectedAddress = range.address;
      const selectedSheetName = sheet.name;

      identifyQueryByCellAddress(queries, selectedAddress, selectedSheetName, selectQueryInList);
    });
  } catch (error) {
    console.error("Error en onCellSelectionChanged:", error);
  }
}
function parseRangeAddress(rangeAddress) {
  const match = rangeAddress.match(/([$]?)([A-Z]+)([$]?)(\d+)(?::([$]?)([A-Z]+)([$]?)(\d+))?/);

  if (!match) {
    throw new Error(`Rango no válido: ${rangeAddress}`);
  }

  const [, , startCol, , startRow, , endCol, , endRow] = match;
  return {
    startColumn: startCol,
    startRow: parseInt(startRow, 10),
    endColumn: endCol || startCol,
    endRow: parseInt(endRow || startRow, 10),
  };
}

function isCellInRange(cellAddress, queryRange) {
  const cell = parseRangeAddress(cellAddress);
  const range = parseRangeAddress(queryRange);

  const startColumn = columnToNumber(cell.startColumn);
  const endColumn = columnToNumber(range.endColumn);

  const isInRowRange = cell.startRow >= range.startRow && cell.startRow <= range.endRow;
  const isInColumnRange = startColumn >= columnToNumber(range.startColumn) && startColumn <= endColumn;

  return isInRowRange && isInColumnRange;
}

function columnToNumber(column) {
  let columnNumber = 0;
  const columnString = column.toUpperCase();

  for (let i = 0; i < columnString.length; i++) {
    columnNumber = columnNumber * 26 + (columnString.charCodeAt(i) - "A".charCodeAt(0) + 1);
  }

  return columnNumber;
}

export function identifyQueryByCellAddress(queries, selectedAddress, selectedSheetName, selectQueryInList) {
  const convertedAddress = convertToAbsoluteRange(selectedAddress);
  let foundQuery = false;

  for (let query of queries) {
    if (selectedSheetName === query.sheetName) {
      if (isCellInRange(convertedAddress, query.rangeAddress)) {
        selectQueryInList(query.queryUUID);
        foundQuery = true;
        break;
      }
    }
  }

  if (!foundQuery) {
    selectQueryInList(null);
  }
}

export async function updateQuerySheet(queryId, executionTime, refreshStatusQuery, data) {
  try {
    return await Excel.run(async (context) => {
      const { sheet: configSheet, values } = await loadWorksheet(context, "DataslayerQueries");
      const rowIndex = findQueryRow(values, queryId);

      if (rowIndex === -1) {
        console.error("Query ID no encontrado en la hoja DataslayerQueries");
        return;
      }

      const updatedDate = getFormattedCurrentDate();

      configSheet.getRangeByIndexes(rowIndex, 4, 1, 1).values = [[updatedDate]];
      configSheet.getRangeByIndexes(rowIndex, 5, 1, 1).values = [["Refreshed successfully"]];
      configSheet.getRangeByIndexes(rowIndex, 8, 1, 1).values = [[executionTime]];

      const startRange = values[rowIndex][2];
      const targetSheetName = values[rowIndex][1];
      const targetSheet = context.workbook.worksheets.getItem(targetSheetName);
      const startCell = startRange.split(":")[0];
      const selectedRange = targetSheet.getRange(startCell);

      const dataRange = selectedRange.getResizedRange(data.length - 1, data[0].length - 1);
      dataRange.values = data;

      await context.sync();

      const updatedQuery = {
        queryUUID: queryId,
        lastUpdate: updatedDate,
        lastStatus: "Refreshed successfully",
        executionTime: executionTime,
      };

      refreshStatusQuery(updatedQuery);
    });
  } catch (error) {
    console.error("Error al actualizar la consulta:", error);
    throw error;
  }
}

export async function createHiddenSheet(context) {
  const workbook = context.workbook;
  let sheet;
  try {
    sheet = workbook.worksheets.getItem("DataslayerQueries");
    await sheet.load("name");
    await context.sync();
  } catch (error) {
    sheet = workbook.worksheets.add("DataslayerQueries");
  }
  sheet.visibility = Excel.SheetVisibility.hidden;
  const headers = [
    "Query UUID",
    "Sheet name",
    "Range address",
    "Created",
    "Updated",
    "Last status",
    "Last refresh ID",
    "Results contain sampled data",
    "Execution time (sec)",
    "Object type",
    "Linked chart ID",
    "Data source",
    "Date range type",
    "Start date",
    "End date",
    "Compare to",
    "Comparison value type",
    "Accounts/views",
    "Metrics",
    "Dimensions",
    "Pivot dimensions",
    "Filters",
    "Segment ID",
    "Segment dim",
    "Sort",
    "Max rows",
    "Max pivot categories",
    "Special settings",
    "Other parameters",
    "Result type",
    "Language/country",
    "Translate to",
    "SQL",
    "Database name",
    "Range address (static)",
    "Report type",
    "Highlight with colour",
    "Refresh with user account",
    "Returned data",
    "Sort dimensions",
    "Created by user account",
    "Blend name",
  ];

  const range = sheet.getRange("A1:AP1");
  range.values = [headers];

  await context.sync();
}

export function writeDataToExcel(
  data,
  startCell = "A1",
  queryUUID,
  queryRequest,
  connector,
  executionTime,
  addOrUpdateQuery,
  isUpdate = false
) {
  return Excel.run(async (context) => {
    let selectedRange, sheetName;

    if (isUpdate) {
      const result = await findRangeForUpdate(context, queryUUID);
      if (!result) return;

      selectedRange = result.selectedRange;
      sheetName = result.sheetName;
    } else {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      sheet.load("name");
      await context.sync();
      selectedRange = getSelectedOrStartRange(context, sheet, startCell);
      sheetName = sheet.name;
    }

    const range = selectedRange.getResizedRange(data.length - 1, data[0].length - 1);
    range.values = data;
    await context.sync();
    range.load("address");
    await context.sync();
    const rangeAddress = convertToAbsoluteRange(range.address);
    const currentDate = getFormattedCurrentDate();
    const queryData = buildQueryData({
      queryUUID,
      sheetName,
      rangeAddress,
      currentDate,
      queryRequest,
      connector,
      executionTime,
      isUpdate,
    });

    isUpdate ? await refreshQuery(queryData, addOrUpdateQuery) : await registerQuery(queryData, addOrUpdateQuery);
  }).catch(console.error);
}

// Función para registrar una nueva query en la hoja DataslayerQueries
export async function registerQuery(queryData, addQuery) {
  addQuery(queryData);

  try {
    await Excel.run(async (context) => {
      const sheetName = "DataslayerQueries";
      const sheet = await getOrCreateSheet(context, sheetName);

      const lastRow = await getLastRow(context, sheet);
      const newRow = buildQueryRow(queryData);
      sheet.getRange(`A${lastRow + 1}:AP${lastRow + 1}`).values = [newRow];

      await context.sync();
    });
  } catch (error) {
    console.error("Error registrando la consulta en Excel:", error);
  }
}

export async function onRemove(queryId) {
  try {
    return await Excel.run(async (context) => {
      // Paso 1: Buscar el nombre de la hoja en DataSlayerQueries
      const { sheet: dataSheet, values } = await loadWorksheet(context, "DataSlayerQueries");
      const rowIndex = findQueryRow(values, queryId);

      if (rowIndex === -1) {
        console.error(`Query ID no encontrado en la hoja DataSlayerQueries`);
        return;
      }

      // Obtener el nombre de la hoja y el rango de la tabla
      const { sheetName, tableRange } = getQueryInfo(values, rowIndex);

      if (!sheetName || !tableRange) {
        console.error(`No se encontró una hoja o rango asociado con queryId: ${queryId}`);
        return;
      }

      // Paso 2: Eliminar la tabla de la hoja de destino
      await deleteTableFromSheet(context, sheetName, tableRange);

      // Paso 3: Eliminar la fila correspondiente en la hoja DataSlayerQueries
      await deleteRowFromSheet(dataSheet, rowIndex);

      await context.sync();
      return queryId;
    });
  } catch (error) {
    console.error("Error al eliminar el query de Excel:", error);
    throw error;
  }
}

export function refreshQuery(query, updatedData) {
  updatedData(query);
  return Excel.run(async (context) => {
    const { sheet, values } = await loadWorksheet(context, "DataslayerQueries");
    const queryRowIndex = findQueryRow(values, query.queryUUID);

    if (queryRowIndex === -1) {
      console.error("Query ID no encontrado en la hoja DataslayerQueries");
      return;
    }

    const newRowValues = buildQueryRow({
      ...query,
      updated: getFormattedCurrentDate(),
      lastStatus: "Refreshed successfully",
    });

    await updateRowInSheet(sheet, queryRowIndex, newRowValues);
  }).catch((error) => {
    console.error("Error al refrescar la query: ", error);
  });
}

// Función para actualizar una fila específica en una hoja de cálculo
async function updateRowInSheet(sheet, rowIndex, newRowValues) {
  const rowRange = sheet.getRange(`A${rowIndex + 1}:AP${rowIndex + 1}`);
  rowRange.values = [newRowValues];
  await sheet.context.sync();
}

async function getOrActivateSheet(context, sheetName) {
  try {
    const sheet = context.workbook.worksheets.getItemOrNullObject(sheetName);
    await context.sync();

    if (sheet.isNullObject) {
      console.error(`La hoja "${sheetName}" no existe en el libro.`);
      return;
    }

    sheet.load("visibility");
    await context.sync();

    if (sheet.visibility === Excel.SheetVisibility.hidden) {
      sheet.visibility = Excel.SheetVisibility.visible;
      await context.sync();
    }
    sheet.activate();
    await context.sync();
    return sheet;
  } catch (error) {
    console.error(`Error al activar la hoja ${sheetName}:`, error);
    throw error;
  }
}

export async function manageQueries() {
  try {
    await Excel.run(async (context) => {
      const sheet = await getOrActivateSheet(context, "DataslayerQueries");
    });
  } catch (error) {
    console.error("Error al visibilizar y redirigir a la hoja DataslayerQueries:", error);
  }
}

//Funcion show params
export async function showParams(queryId) {
  try {
    await Excel.run(async (context) => {
      const sheet = await getOrActivateSheet(context, "DataslayerQueries");
      const { values } = await loadWorksheet(context, "DataslayerQueries");
      const rowIndex = findQueryRow(values, queryId);

      if (rowIndex === -1) {
        console.error("Query ID no encontrado en la hoja DataslayerQueries");
        return;
      }

      const cellAddress = `A${rowIndex + 1}`;
      const selectedRange = sheet.getRange(cellAddress);
      selectedRange.select();
      await context.sync();
    });
  } catch (error) {
    console.error("Error al navegar al query:", error);
  }
}

export async function goToQueryResult(queryId) {
  try {
    await Excel.run(async (context) => {
      const { sheet, values } = await loadWorksheet(context, "DataslayerQueries");

      const rowIndex = findQueryRow(values, queryId);

      if (rowIndex === -1) {
        console.error("Query ID no encontrado en la hoja DataslayerQueries");
        return;
      }

      const { sheetName, tableRange } = getQueryInfo(values, rowIndex);

      if (!sheetName || !tableRange) {
        console.error("No se encontró una hoja o rango asociado con queryId:", queryId);
        return;
      }

      const targetSheet = context.workbook.worksheets.getItem(sheetName);
      targetSheet.activate();
      await context.sync();

      const tableRangeToSelect = targetSheet.getRange(tableRange);
      tableRangeToSelect.select();
      await context.sync();
    });
  } catch (error) {
    console.error("Error al navegar al resultado del query:", error);
  }
}
