export const dateRangeOptions = [
  { id: 1, value: "Today" },
  { id: 2, value: "Yesterday" },
  { id: 3, value: "Last week (Sun-Sat)" },
  { id: 4, value: "Last week (Mon-Sun)" },
  { id: 5, value: "This month to date" },
  { id: 6, value: "This month to yesterday" },
  { id: 7, value: "Last month" },
  { id: 8, value: "Year to date" },
  { id: 9, value: "Last year" },
  { id: 10, value: "Last year & this year to date" },
  { id: 11, value: "Last X days" },
  { id: 12, value: "Last X weeks (Sun-Sat)" },
  { id: 13, value: "Last X weeks (Mon-Sun)" },
  { id: 14, value: "Last X months" },
  { id: 15, value: "Last X years" },
  { id: 16, value: "Custom date range" },
];

export const compareToOptions = [
  { id: 1, value: "None" },
  { id: 2, value: "Previous period of same length" },
  { id: 3, value: "Same dates a year ago" },
  { id: 4, value: "Same dates a year ago, matching day of week" },
  { id: 5, value: "Custom date range" },
];

export const comparisonTypeOptions = [
  { id: 1, value: "Absolute change" },
  { id: 2, value: "Percentage change" },
  { id: 3, value: "Comparison value" },
];

export const limitOptions = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 250, label: "250" },
  { value: 500, label: "500" },
  { value: 1000, label: "1000" },
  { value: 5000, label: "5000" },
  { value: 10000, label: "10000" },
  { value: 25000, label: "25000" },
  { value: 0, label: "All" },
];
