import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Input,
  useId,
  DialogSurface,
  DialogBody,
  makeStyles,
  Select,
  Field,
  Checkbox,
  Label,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import {
  calculatePreviousPeriod,
  calculateSameDatesAYearAgo,
  calculateSameDatesAYearAgoMatchingDay,
  calculateDateRange,
  onFormatDate,
} from "../../utilities/formatDates";
import { dateRangeOptions, compareToOptions, comparisonTypeOptions } from "./../../utilities/datesFilterConstants";

const useStyles = makeStyles({
  dialogWidth: {
    width: "80%",
    maxWidth: "500px",
    margin: "auto",
  },
  dialogBody: {
    display: "flex!important",
    flexDirection: "column!important",
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  fieldDatePicker: {
    display: "flex",
    flexDirection: "column",
    width: "45%",
  },
  container: {
    margin: "10px 0",
  },
});

const DateRangeDialog = ({ open, handleClose, handleApply, handleDiscard, onDateSelect }) => {
  const styles = useStyles();
  const selectId = useId();
  const today = new Date();
  const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
  const [selectedDateOptionId, setSelectedDateOptionId] = React.useState(dateRangeOptions[15].id);
  const [selectedDateOptionName, setSelectedDateOptionName] = React.useState(dateRangeOptions[15].value);
  const [selectedCompareOption, setSelectedCompareOption] = React.useState(compareToOptions[0].id);
  const [selectedCompareOptionName, setSelectedCompareOptionName] = React.useState(compareToOptions[0].value);
  const [comparisonType, setComparisonType] = React.useState("Absolute change");
  const [customDateRange, setCustomDateRange] = React.useState(1);
  const [includeCurrent, setIncludeCurrent] = React.useState(false);
  const [startDate, setStartDate] = React.useState(firstDayOfYear);
  const [endDate, setEndDate] = React.useState(today);
  const [comparisonDates, setComparisonDates] = React.useState({ start: null, end: null });

  const onCalculateComparisonDates = (type, startDate, endDate) => {
    let dates;
    switch (type) {
      case 2:
        dates = calculatePreviousPeriod(startDate, endDate);
        break;
      case 3:
        dates = calculateSameDatesAYearAgo(startDate, endDate);
        break;
      case 4:
        dates = calculateSameDatesAYearAgoMatchingDay(startDate, endDate);
        break;
      case 5:
        dates = { newStartDate: firstDayOfYear, newEndDate: today };
        break;
      default:
        dates = { newStartDate: null, newEndDate: null };
        break;
    }
    setComparisonDates({ start: dates.newStartDate, end: dates.newEndDate });
  };

  const handleChangeDate = (event) => {
    const selectedId = parseInt(event.target.value, 10); // Get the selected id
    const selectedOption = dateRangeOptions.find((option) => option.id === selectedId);
    if (selectedOption) {
      setSelectedDateOptionId(selectedOption.id);
      setSelectedDateOptionName(selectedOption.value);
    }
    const { startDate: newStartDate, endDate: newEndDate } = calculateDateRange(
      selectedId,
      customDateRange,
      includeCurrent
    );

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    onCalculateComparisonDates(selectedCompareOption, newStartDate, newEndDate);
  };

  const updateDates = () => {
    const { startDate, endDate } = calculateDateRange(selectedDateOptionId, customDateRange, includeCurrent);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  React.useEffect(() => {
    updateDates();
  }, [customDateRange, includeCurrent, selectedDateOptionId]);

  const handleChangeCompare = (event) => {
    const selectedId = parseInt(event.target.value, 10);
    const selectedOption = compareToOptions.find((option) => option.id === selectedId);
    if (selectedOption) {
      setSelectedCompareOption(selectedOption.id);
      setSelectedCompareOptionName(selectedOption.value);
    }
    onCalculateComparisonDates(selectedOption.id, startDate, endDate);
  };

  const getLabelForOption = (isSingular = false) => {
    if (selectedDateOptionName.includes("day")) {
      return isSingular ? "day" : "days";
    } else if (selectedDateOptionName.includes("week")) {
      return isSingular ? "week" : "weeks";
    } else if (selectedDateOptionName.includes("month")) {
      return isSingular ? "month" : "months";
    } else if (selectedDateOptionName.includes("year")) {
      return isSingular ? "year" : "years";
    } else {
      return "";
    }
  };
  const renderCustomInput = () => {
    if (
      ["Last X days", "Last X weeks (Sun-Sat)", "Last X weeks (Mon-Sun)", "Last X months", "Last X years"].includes(
        selectedDateOptionName
      )
    ) {
      return (
        <div className={styles.container}>
          <Label>Last</Label>
          <Input
            type="number"
            value={customDateRange}
            onChange={(e) => setCustomDateRange(e.target.value)}
            style={{ width: "50px" }}
          />
          <Label>{getLabelForOption()}</Label>
          <Checkbox
            label={`including this ${getLabelForOption(true)}`}
            checked={includeCurrent}
            onChange={(e) => setIncludeCurrent(e.target.checked)}
          />
        </div>
      );
    }
    return null;
  };

  const renderComparisonFields = () => {
    if (selectedCompareOptionName !== "None") {
      return (
        <div className={styles.container}>
          <Label>Comparison type</Label>
          <Select value={comparisonType} onChange={(e) => setComparisonType(e.target.value)}>
            {comparisonTypeOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.value}
              </option>
            ))}
          </Select>

          <div className="flex justify-between my-3">
            <Field className={styles.fieldDatePicker} label="Start">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                allowTextInput
                placeholder="Select a date..."
                className={styles.control}
                formatDate={onFormatDate}
                value={comparisonDates.start}
              />
            </Field>

            <Field className={styles.fieldDatePicker} label="End">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                allowTextInput
                placeholder="Select a date..."
                className={styles.control}
                formatDate={onFormatDate}
                value={comparisonDates.end}
              />
            </Field>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <Dialog open={open}>
      <DialogSurface className={styles.dialogWidth}>
        <DialogBody className={styles.dialogBody}>
          <DialogTitle className={styles.dialogTitle}>
            Dates
            <div className="gap-3 flex">
              <Button
                appearance="primary"
                size="small"
                onClick={() => {
                  if (startDate && endDate) {
                    const dates = {
                      date_range_type: selectedDateOptionName,
                      start_date: startDate,
                      end_date: endDate,
                    };
                    onDateSelect(dates);
                    handleApply(dates);
                  }
                }}
              >
                Apply
              </Button>
              <Button size="small" onClick={handleDiscard}>
                Discard
              </Button>
            </div>
          </DialogTitle>
          <DialogContent>
            <Select value={selectedDateOptionId} onChange={handleChangeDate}>
              {dateRangeOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.value}
                </option>
              ))}
            </Select>
            {renderCustomInput()}
            <div className="flex justify-between my-3">
              <Field className={styles.fieldDatePicker} label="Start">
                <DatePicker
                  formatDate={onFormatDate}
                  allowTextInput
                  placeholder="Select a date..."
                  className={styles.control}
                  value={startDate}
                />
              </Field>

              <Field className={styles.fieldDatePicker} label="End">
                <DatePicker
                  formatDate={onFormatDate}
                  allowTextInput
                  placeholder="Select a date..."
                  className={styles.control}
                  value={endDate}
                />
              </Field>
            </div>
            <label htmlFor={selectId}>Compare to</label>
            <Select id={selectId} value={selectedCompareOption} onChange={handleChangeCompare}>
              {compareToOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.value}
                </option>
              ))}
            </Select>
            {renderComparisonFields()}
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default DateRangeDialog;
