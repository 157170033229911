import * as React from "react";
import {
  Select,
  Button,
  makeStyles,
  tokens,
  Caption1,
  Caption1Strong,
  Body1,
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerOption,
  TagPickerGroup,
  Label,
} from "@fluentui/react-components";
import { Tag } from "@fluentui/react-components";
import { Delete16Filled } from "@fluentui/react-icons";
import DateRangeDialog from "./DateRangeDialog";
import SortMetricsDialog from "./SortMetricsDialog";
import SortDimsDialog from "./SortDimsDialog";
import FiltersDialog from "./FiltersDialog";
import { onFormatDate } from "../../utilities/formatDates";
import { limitOptions } from "../../utilities/datesFilterConstants";
import { specialConnectors } from "../../utilities/connectorsConfiguration";

const useStyles = makeStyles({
  container: {
    padding: "10px",
  },
  dateRangeInput: {
    marginBottom: "10px",
    width: "100%",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  inputGroup: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  inputLabel: {
    margin: "5px!important",
    display: "inline-block",
  },
  inputSelect: {
    flex: "2",
  },
  deleteIcon: {
    color: tokens.colorPalettePurpleBackground2,
    cursor: "pointer",
    marginLeft: "5px",
  },
  numberSelect: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "5px",
    marginBottom: "10px",
  },
  metricsDelete: {
    float: "right",
  },
  groupName: {
    fontWeight: "bold!important",
    textTransform: "uppercase",
  },
});

const DataTableAccordion = ({
  datasource,
  filters,
  dimensions,
  sourceMetrics,
  selectedDimsOptions = [],
  selectedDimsColsOptions = [],
  selectedMetricsOptions = [],
  appliedFilters = [],
  appliedMetricsSorts = [],
  appliedDimsSorts = [],
  setSelectedDimsOptions,
  setSelectedDimsColsOptions,
  setSelectedMetricsOptions,
  setAppliedFilters,
  setAppliedMetricsSorts,
  setAppliedDimsSorts,
  onDatesChange,
  dates,
  setDates,
  limits,
  setLimits,
}) => {
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openSortMetrics, setOpenSortMetrics] = React.useState(false);
  const [openSortDims, setOpenSortDims] = React.useState(false);
  const [openFilters, setOpenFilters] = React.useState(false);

  const [dims, setDims] = React.useState(dimensions || {});
  const [metrics, setMetrics] = React.useState(sourceMetrics || {});

  const [filterDims, setFilterDims] = React.useState("");
  const [filterMetrics, setFilterMetrics] = React.useState("");
  const [filterDimsColumns, setFilterDimsColumns] = React.useState("");

  const isSpecialConnector = specialConnectors.find((connector) => connector.key === datasource);

  const combinedDims = [...selectedDimsOptions, ...selectedDimsColsOptions];
  React.useEffect(() => {
    if (dimensions) setDims(dimensions);
    if (sourceMetrics) setMetrics(sourceMetrics);
  }, [dimensions, sourceMetrics]);

  const tagPickerDimsOptions = React.useMemo(() => {
    return Object.entries(dims).reduce((acc, [group, items]) => {
      const filteredDimsItems = items.filter(
        (item) => !selectedDimsOptions.includes(item.name) && item.name.toLowerCase().includes(filterDims)
      );
      if (filteredDimsItems.length > 0) {
        acc.push({ group, items: filteredDimsItems });
      }
      return acc;
    }, []);
  }, [dims, filterDims, selectedDimsOptions]);

  const tagPickerDimsColOptions = React.useMemo(() => {
    return Object.entries(dims).reduce((acc, [group, items]) => {
      const filteredDimsColsItems = items.filter(
        (item) => !selectedDimsColsOptions.includes(item.name) && item.name.toLowerCase().includes(filterDimsColumns)
      );
      if (filteredDimsColsItems.length > 0) {
        acc.push({ group, items: filteredDimsColsItems });
      }
      return acc;
    }, []);
  }, [dims, filterDimsColumns, selectedDimsColsOptions]);

  const tagPickerMetricsOptions = React.useMemo(() => {
    return Object.entries(metrics).reduce((acc, [group, items]) => {
      const filteredMetricsItems = items.filter(
        (item) => !selectedMetricsOptions.includes(item.name) && item.name.toLowerCase().includes(filterMetrics)
      );
      if (filteredMetricsItems.length > 0) {
        acc.push({ group, items: filteredMetricsItems });
      }
      return acc;
    }, []);
  }, [metrics, filterMetrics, selectedMetricsOptions]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateSelection = (dates) => {
    const formattedDates = {
      start_date: new Date(dates.start_date),
      end_date: new Date(dates.end_date),
      date_range_type: dates.date_range_type,
    };
    setDates(formattedDates);
    onDatesChange(formattedDates);
  };

  const handleApply = (dates) => {
    const formattedDates = {
      start_date: new Date(dates.start_date),
      end_date: new Date(dates.end_date),
      date_range_type: dates.date_range_type,
    };
    setDates(formattedDates);
    setOpen(false);
  };

  const handleDiscard = () => {
    setOpen(false);
  };

  const handleApplyFilters = (filters) => {
    setAppliedFilters(filters);
    setOpenFilters(false);
  };

  const handleApplySorts = (sorts) => {
    setAppliedMetricsSorts(sorts);
    setOpenSortMetrics(false);
  };

  const handleApplyDimsSorts = (sorts) => {
    setAppliedDimsSorts(sorts);
    setOpenSortDims(false);
  };

  const handleDimsOptionSelect = (e, data) => {
    const selectedDims = data.selectedOptions.map((id) => {
      const dimension = Object.values(dims)
        .flat()
        .find((dim) => dim.id === id);
      return { id: dimension.id, name: dimension.name };
    });
    setSelectedDimsOptions(selectedDims);
  };

  const handleDimsColsOptionSelect = (e, data) => {
    const selectedDimsCol = data.selectedOptions.map((id) => {
      const dimension = Object.values(dims)
        .flat()
        .find((dim) => dim.id === id);
      return { id: dimension.id, name: dimension.name };
    });
    setSelectedDimsColsOptions(selectedDimsCol);
  };

  return (
    <>
      <DateRangeDialog
        open={open}
        handleClose={handleClose}
        handleApply={handleApply}
        handleDiscard={handleDiscard}
        onDateSelect={handleDateSelection}
      />
      <SortMetricsDialog
        open={openSortMetrics}
        handleClose={() => setOpenSortMetrics(false)}
        handleApply={handleApplySorts}
        handleDiscard={() => setOpenSortMetrics(false)}
        selectedMetrics={selectedMetricsOptions}
        initialSorts={appliedMetricsSorts}
      />
      <SortDimsDialog
        open={openSortDims}
        handleClose={() => setOpenSortDims(false)}
        handleApply={handleApplyDimsSorts}
        handleDiscard={() => setOpenSortDims(false)}
        selectedDims={combinedDims}
        initialSorts={appliedDimsSorts}
      />
      <FiltersDialog
        open={openFilters}
        handleClose={handleClose}
        handleApply={handleApplyFilters}
        handleDiscard={() => setOpenFilters(false)}
        dimensions={dims}
        filtersList={filters}
        initialFilters={appliedFilters}
      />

      {datasource ? (
        <div className={styles.container}>
          {!isSpecialConnector && (
            <Button onClick={() => setOpen(true)} className={styles.dateRangeInput}>
              <Body1>{dates && `${onFormatDate(dates.start_date)} - ${onFormatDate(dates.end_date)}`}</Body1>
            </Button>
          )}

          <div className={styles.buttonGroup}>
            <Button onClick={() => setOpenSortMetrics(true)} size="large" className="flex flex-col">
              <Caption1Strong>Sort metrics</Caption1Strong>
              <Caption1>{appliedMetricsSorts.length}</Caption1>
            </Button>
            <Button onClick={() => setOpenSortDims(true)} size="large" className="flex flex-col">
              <Caption1Strong>Sort dims</Caption1Strong>
              <Caption1>{appliedDimsSorts.length}</Caption1>
            </Button>
            <Button onClick={() => setOpenFilters(true)} size="large" className="flex flex-col">
              <Caption1Strong>Filters</Caption1Strong>
              <Caption1>{appliedFilters.length}</Caption1>
            </Button>
          </div>

          <div className="mb-3" id="metric-container">
            <Caption1 className={styles.inputLabel}>Select metrics</Caption1>
            <Button
              onClick={() => setSelectedMetricsOptions([])}
              className={styles.metricsDelete}
              appearance="transparent"
              icon={<Delete16Filled />}
            />
            <div id="select-container">
              <TagPicker
                className="w-full"
                positioning={{ position: "below", align: "bottom" }}
                onOptionSelect={(e, data) => setSelectedMetricsOptions(data.selectedOptions)}
                selectedOptions={selectedMetricsOptions}
              >
                <TagPickerControl>
                  <TagPickerGroup>
                    {selectedMetricsOptions.map((option) => (
                      <Tag key={option} shape="rounded" value={option}>
                        {option}
                      </Tag>
                    ))}
                  </TagPickerGroup>
                  <TagPickerInput
                    aria-label="Select Metrics"
                    onChange={(e) => setFilterMetrics(e.target.value.toLowerCase())}
                  />
                </TagPickerControl>
                <TagPickerList>
                  {tagPickerMetricsOptions.length > 0
                    ? tagPickerMetricsOptions.map(({ group, items }) => (
                        <React.Fragment key={group}>
                          <Label id="metric-label" className={styles.groupName}>
                            {group}
                          </Label>
                          {items.map((item) => (
                            <TagPickerOption secondaryContent="" value={item.id} key={item.id}>
                              {item.name}
                            </TagPickerOption>
                          ))}
                        </React.Fragment>
                      ))
                    : "No options available"}
                </TagPickerList>
              </TagPicker>
            </div>
          </div>
          <div className="mb-3" id="dims-rows-container">
            <Caption1 className={styles.inputLabel}>Select dims in rows</Caption1>
            <Button
              onClick={() => setSelectedDimsOptions([])}
              className={styles.metricsDelete}
              appearance="transparent"
              icon={<Delete16Filled />}
            />
            <div id="select-container">
              <TagPicker
                className="w-full"
                positioning={{ position: "below", align: "bottom" }}
                onOptionSelect={handleDimsOptionSelect}
                selectedOptions={selectedDimsOptions.map((option) => option.id)}
              >
                <TagPickerControl>
                  <TagPickerGroup>
                    {selectedDimsOptions.map((option) => (
                      <Tag key={option.id} shape="rounded" value={option.id}>
                        {option.name}
                      </Tag>
                    ))}
                  </TagPickerGroup>
                  <TagPickerInput
                    aria-label="Select Dimensions"
                    onChange={(e) => setFilterDims(e.target.value.toLowerCase())}
                  />
                </TagPickerControl>
                <TagPickerList>
                  {tagPickerDimsOptions.length > 0
                    ? tagPickerDimsOptions.map(({ group, items }) => (
                        <React.Fragment key={group}>
                          <Label id="dim-row-label" className={styles.groupName}>
                            {group}
                          </Label>
                          {items.map((item) => (
                            <TagPickerOption secondaryContent="" value={item.id} text={item.name} key={item.id}>
                              {item.name}
                            </TagPickerOption>
                          ))}
                        </React.Fragment>
                      ))
                    : "No options available"}
                </TagPickerList>
              </TagPicker>
            </div>
          </div>

          <div className={styles.numberSelect}>
            <label htmlFor="selectRows"># of rows</label>
            <Select
              id="selectRows"
              value={limits.limitRows}
              onChange={(e) => {
                setLimits({
                  limitRows: parseInt(e.target.value),
                  limitCols: limits.limitCols,
                });
              }}
            >
              {limitOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </div>

          <div className="mb-3" id="dims-columns-container">
            <Caption1 className={styles.inputLabel}>Select dims in cols</Caption1>
            <Button
              onClick={() => setSelectedDimsColsOptions([])}
              className={styles.metricsDelete}
              appearance="transparent"
              icon={<Delete16Filled />}
            />
            <div id="select-container">
              <TagPicker
                className="w-full"
                positioning={{ position: "below", align: "bottom" }}
                onOptionSelect={handleDimsColsOptionSelect}
                selectedOptions={selectedDimsColsOptions.map((option) => option.id)}
              >
                <TagPickerControl>
                  <TagPickerGroup>
                    {selectedDimsColsOptions.map((option) => (
                      <Tag key={option.id} shape="rounded" value={option.id}>
                        {option.name}
                      </Tag>
                    ))}
                  </TagPickerGroup>
                  <TagPickerInput
                    aria-label="Select Employees"
                    onChange={(e) => setFilterDimsColumns(e.target.value.toLowerCase())}
                  />
                </TagPickerControl>
                <TagPickerList>
                  {tagPickerDimsColOptions.length > 0
                    ? tagPickerDimsColOptions.map(({ group, items }) => (
                        <React.Fragment key={group}>
                          <Label id="dim-col-label" className={styles.groupName}>
                            {group}
                          </Label>
                          {items.map((item) => (
                            <TagPickerOption secondaryContent="" value={item.id} text={item.name} key={item.id}>
                              {item.name}
                            </TagPickerOption>
                          ))}
                        </React.Fragment>
                      ))
                    : "No options available"}
                </TagPickerList>
              </TagPicker>
            </div>
          </div>

          <div className={styles.numberSelect}>
            <label htmlFor="selectCols"># of cols</label>
            <Select
              id="selectCols"
              value={limits.limitCols}
              onChange={(e) => {
                setLimits({
                  limitRows: limits.limitRows,
                  limitCols: parseInt(e.target.value),
                });
              }}
            >
              {limitOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default React.memo(DataTableAccordion);
