import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogSurface,
  DialogBody,
  makeStyles,
  Caption1,
  Select,
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  dialogWidth: {
    width: "80%",
    maxWidth: "500px",
    margin: "auto",
  },
  dialogBody: {
    display: "flex!important",
    flexDirection: "column!important",
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  deleteButton: {
    minWidth: "10%!important",
  },
});

const AddSortComponent = ({ metrics = [], selectedMetric, sortOrder, onRemove, onMetricChange, onSortOrderChange }) => {
  const styles = useStyles();

  return (
    <div className="flex justify-between my-4">
      <Select className={styles.select} value={selectedMetric} onChange={(e) => onMetricChange(e.target.value)}>
        <option value="" disabled>
          Select dimension
        </option>
        {metrics.map((metric) => (
          <option key={metric.id} value={metric.id}>
            {metric.name}
          </option>
        ))}
      </Select>

      <Select className={styles.select} value={sortOrder} onChange={(e) => onSortOrderChange(Number(e.target.value))}>
        <option value={1}>Asc</option>
        <option value={-1}>Desc</option>
      </Select>

      <Button className={styles.deleteButton} onClick={onRemove}>
        <DismissRegular />
      </Button>
    </div>
  );
};

const SortDimsDialog = ({ open, initialSorts, handleClose, handleApply, handleDiscard, selectedDims }) => {
  const styles = useStyles();
  const [dims, setDims] = React.useState(selectedDims);
  const [sorts, setSorts] = React.useState([]);
  React.useEffect(() => {
    setDims(selectedDims);
  }, [selectedDims]);

  React.useEffect(() => {
    setSorts(initialSorts || []);
  }, [initialSorts]);

  const handleDimChange = (index, dim) => {
    const newSorts = [...sorts];
    newSorts[index].dim = dim;
    setSorts(newSorts);
  };

  const handleSortOrderChange = (index, order) => {
    const newSorts = [...sorts];
    newSorts[index].order = Number(order);
    setSorts(newSorts);
  };
  const addSort = () => {
    setSorts([...sorts, { dim: "", order: 1 }]);
  };

  const removeSort = (index) => {
    setSorts(sorts.filter((_, i) => i !== index));
  };

  const discardDialog = () => {
    setSorts(initialSorts || []);
    handleDiscard();
  };

  const applySorts = () => {
    handleApply(sorts);
    setSorts(initialSorts || []);
    handleClose();
  };

  return (
    <Dialog open={open}>
      <DialogSurface className={styles.dialogWidth}>
        <DialogBody className={styles.dialogBody}>
          <DialogTitle className={styles.dialogTitle}>
            Sort dims
            <div className="gap-3 flex">
              <Button appearance="primary" size="small" onClick={applySorts}>
                Apply
              </Button>
              <Button onClick={discardDialog} size="small">
                Discard
              </Button>
            </div>
          </DialogTitle>
          <DialogContent>
            {dims.length === 0 ? (
              <Caption1>You must select a dimension before sorting</Caption1>
            ) : (
              <div>
                {sorts.map((sort, index) => (
                  <AddSortComponent
                    key={index}
                    metrics={dims}
                    selectedMetric={sort.dim}
                    sortOrder={sort.order}
                    onRemove={() => removeSort(index)}
                    onMetricChange={(dim) => handleDimChange(index, dim)}
                    onSortOrderChange={(order) => handleSortOrderChange(index, order)}
                  />
                ))}
              </div>
            )}
            {dims.length > 0 && sorts.length < dims.length && (
              <Button onClick={addSort} appearance="transparent">
                + Add sort
              </Button>
            )}
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default React.memo(SortDimsDialog);
