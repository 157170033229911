import React, { useState } from "react";
import { makeStyles, Input, Button, Popover, Label, PopoverTrigger, PopoverSurface } from "@fluentui/react-components";
import { Calendar } from "@fluentui/react-calendar-compat";
import { CalendarMonthRegular } from "@fluentui/react-icons";
import { isValidDate, onFormatDate } from "../../utilities/formatDates";
const useStyles = makeStyles({
  container: {
    display: "flex",
    gap: "10px",
    position: "relative",
  },
});

const CustomDateInput = ({ onSelectDate, selectedDate }) => {
  const styles = useStyles();
  const [showDatePicker, setShowDatePicker] = useState(false);

  const headerId = React.useId();
  const inputRef = React.useRef(null);
  const positioningRef = React.useRef(null);
  React.useEffect(() => {
    if (inputRef.current) {
      positioningRef.current?.setTarget(inputRef.current);
    }
  }, [inputRef, positioningRef]);

  const handleInputChange = (event) => {
    onSelectDate(event.target.value);
  };
  

  return (
    <div className={styles.container}>
      <Input
        id="datepicker"
        ref={inputRef}
        contentAfter={
          <CalendarMonthRegular
            className={styles.calendarIcon}
            onClick={() => setShowDatePicker((s) => !s)}
            aria-label="Open Calendar"
          />
        }
        value={isValidDate(selectedDate) ? onFormatDate(selectedDate) : selectedDate}
        onChange={handleInputChange}
      />
      <Popover
        open={showDatePicker}
        onOpenChange={(_, data) => setShowDatePicker(data.open)}
        trapFocus
        positioning={{ positioningRef }}
      >
        <PopoverSurface aria-labelledby={headerId}>
          <div>
            <h3 id={headerId}>Select Date</h3>
            <div>
              <Calendar
                showMonthPickerAsOverlay
                highlightSelectedMonth
                showGoToToday={false}
                onSelectDate={onSelectDate}
                value={selectedDate}
              />
            </div>
          </div>
        </PopoverSurface>
      </Popover>
    </div>
  );
};

export default CustomDateInput;
