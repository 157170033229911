import * as React from "react";
import {
  Accordion,
  useId,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Input,
  makeStyles,
  typographyStyles,
  Image,
  Button,
  Checkbox,
} from "@fluentui/react-components";
import { SearchFilled } from "@fluentui/react-icons";

import { getAccountByDataSource, getDataTables } from "../../services/connector";
import { config } from "../../Constants";
import { useDialog } from "../context/DialogContext";
import { useMutation } from "react-query";
import RunningDialog from "./RunningDialog";
import { connectorConfigurations, specialConnectors } from "../../utilities/connectorsConfiguration";
import { showDialog } from "../../utilities/excel";

const useStyles = makeStyles({
  searchInput: { display: "flex", flexDirection: "column", gap: "2px", margin: "20px 10px" },
  logoImg: { marginRight: "7px", padding: "5px" },
  connectorTitle: typographyStyles.body1,
  manageAccounts: { display: "flex", flexDirection: "column", gap: "2px", margin: "20px 10px" },
});
const DATASLAYER_URL = config.DATASLAYER_URL;

const ConnectorsAccordion = React.forwardRef((props, ref) => {
  const styles = useStyles();
  const {
    datasources,
    onConnectionChange,
    onTaskIdChange,
    selectedQuery,
    onChangeConnectors,
    selectedConnectors,
    setInfoConnector,
    setConfiguration,
    setStablishedConnection,
    onAccountsChange,
  } = props;
  const token = localStorage.getItem("DS-TOKEN");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedConnections, setSelectedConnections] = React.useState(
    selectedConnectors ? { selectedConnectors } : {}
  );
  const { dialogOpen, setDialogOpen } = useDialog();

  const beforeId = useId("content-before");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  let dataSourcesEntries = [];

  if (datasources && datasources.datasources) {
    dataSourcesEntries = Object.entries(datasources.datasources).sort((a, b) => {
      if (a[0] < b[0]) return -1;
      if (a[0] > b[0]) return 1;
      return 0;
    });
  }

  const filteredEntries = dataSourcesEntries.filter(([key, value]) =>
    value.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCheckboxChange = (conectorKey, connection) => {
    setSelectedConnections((prevSelected) => {
      const connectorSelections = prevSelected[conectorKey] || [];
      const isSelected = connectorSelections.some((conn) => conn.id === connection.id);

      if (isSelected) {
        return {
          ...prevSelected,
          [conectorKey]: connectorSelections.filter((conn) => conn.id !== connection.id),
        };
      } else {
        return {
          ...prevSelected,
          [conectorKey]: [...connectorSelections, connection],
        };
      }
    });

    onChangeConnectors((prevSelected) => {
      const connectorSelections = prevSelected[conectorKey] || [];
      const isSelected = connectorSelections.some((conn) => conn.id === connection.id);

      if (isSelected) {
        return {
          ...prevSelected,
          [conectorKey]: connectorSelections.filter((conn) => conn.id !== connection.id),
        };
      } else {
        return {
          ...prevSelected,
          [conectorKey]: [...connectorSelections, connection],
        };
      }
    });
  };

  const mutation = useMutation(
    async ({ connectorKey, connectionsToSubmit, connectorName }) => {
      const response = await getAccountByDataSource({
        token,
        datasource: connectorKey,
        connections: connectionsToSubmit,
      });
      if (response.ok) return await response.json();
      throw new Error("Error confirming connection");
    },
    {
      onSuccess: (res, { connectorKey, connectorName }) => {
        onTaskIdChange(res.task_id);
        onConnectionChange(connectorName, connectorKey);
      },
      onError: (error) => {
        setDialogOpen(false);
      },
    }
  );

  const dataBaseMutation = useMutation(
    async ({ connectionsToSubmit, connectorName, conectorKey }) => {
      const response = await getDataTables({
        token,
        connections: connectionsToSubmit,
      });
      if (response.ok) return await response.json();
      throw new Error("Error confirming database connection");
    },
    {
      onSuccess: (res, { connectorKey, connectorName }) => {
        onTaskIdChange(res.task_id);
        onConnectionChange(connectorName, connectorKey);
      },
      onError: (error) => {
        setDialogOpen(false);
      },
    }
  );

  const confirmConnection = (connectorKey, connectorName, source) => {
    setDialogOpen(true);
    const isSpecialConnector = specialConnectors.find((connector) => connector.key === connectorKey);
    const connectionsToSubmit = selectedConnections[connectorKey] || [];
    setInfoConnector(source);
    if (isSpecialConnector) {
      if (source?.hub_account_type === "unique") {
        if (connectionsToSubmit.length > 1) {
          showDialog("Select only one hub account", ["You must select only one hub account to continue"]);
        }
        if (connectionsToSubmit.length === 0) {
          showDialog("Select an account", ["You must select a hub account to continue"]);
        }
        if (connectorKey === "database") {
          dataBaseMutation.mutate({ connectionsToSubmit, connectorName, connectorKey });
          evaluateConnectorConfiguration(connectorKey);
          setStablishedConnection(true);
          onConnectionChange(connectorName, connectorKey);
          setSelectedConnections(connectionsToSubmit);
          setDialogOpen(false);
        } else {
          evaluateConnectorConfiguration(connectorKey);
          setStablishedConnection(true);
          onConnectionChange(connectorName, connectorKey);
          setSelectedConnections(connectionsToSubmit);
          setDialogOpen(false);
        }
      } else {
        evaluateConnectorConfiguration(connectorKey);
        setStablishedConnection(true);
        onConnectionChange(connectorName, connectorKey);
        setDialogOpen(false);
      }
    } else {
      mutation.mutate({ connectorKey, connectionsToSubmit, connectorName });
    }
  };

  const evaluateConnectorConfiguration = (key) => {
    const config = connectorConfigurations[key];
    if (config) {
      const connectorConfig = config;
      setConfiguration(connectorConfig);
    }
  };

  React.useImperativeHandle(ref, () => ({
    confirmConnection,
    handleCheckboxChange,
  }));

  return (
    <div>
      <RunningDialog dialogOpen={dialogOpen} />
      <Accordion collapsible>
        <div className={styles.searchInput}>
          <Input
            placeholder="Search connectors"
            contentBefore={<SearchFilled />}
            id={beforeId}
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        {filteredEntries && filteredEntries.length > 0 ? (
          filteredEntries.flatMap(([key, source], index) => (
            <AccordionItem key={key} value={String(index + 1)}>
              <AccordionHeader
                onClick={() => {
                  if (source?.hub_account_type === null) {
                    confirmConnection(key, source.name, source);
                  }
                }}
                expandIconPosition="end"
              >
                <Image
                  className={styles.logoImg}
                  alt={`logo connector ${index + 1}`}
                  src={`../assets/${key}.png`}
                  height={25}
                  width={25}
                />
                <span className={styles.connectorTitle}>{source.name}</span>
              </AccordionHeader>
              <AccordionPanel>
                <div>
                  {source.connections.map((connection, index) => (
                    <div key={index}>
                      <Checkbox
                        label={connection.id}
                        value={connection.id}
                        checked={selectedConnections[key]?.some((conn) => conn.id === connection.id) || false}
                        onChange={() => handleCheckboxChange(key, connection)}
                      />
                    </div>
                  ))}

                  {source.hub_account_type !== null && (
                    <div className="flex justify-around">
                      <Button as="a" href={`${DATASLAYER_URL}/hub/${key}?full=1`}>
                        Manage Hub Accounts
                      </Button>
                      <Button appearance="primary" onClick={() => confirmConnection(key, source.name, source)}>
                        Confirm
                      </Button>
                    </div>
                  )}
                </div>
              </AccordionPanel>
            </AccordionItem>
          ))
        ) : (
          <p>No data available</p>
        )}
      </Accordion>
    </div>
  );
});

ConnectorsAccordion.displayName = "ConnectorsAccordion";

export default ConnectorsAccordion;
